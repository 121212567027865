
audio {
	display: none;
}

.music-control {
	display: inline-block;
	padding: 3px 4px 4px 4px;
	transform: scale(1);
	margin-right: 16px;
	position: relative;
	border-radius: 4px;
	transition: background-color 0.4s ease;
	font-size: 0;
	.s--desktop &:hover {
		background-color: var(--foreground);
		.music-control__bar {
			background-color: var(--background);
		}
	}
	.s--mobile-size & {
		position: absolute;
    top: 1px;
    left: -45px;
    transform: scaleY(0.8);
	}
	&__bar-wrap {
		position: relative;
		display: inline-block;
		width: 22px;
		height: 13px;
	}
	&__bar {
		position: absolute;
		display: inline-block;
		bottom: 0;
		width: 2px;
		height: 1px;
		transform-origin: center bottom;
		will-change: transform;
		background-color: var(--foreground);
		transition: background-color 0.4s ease, transform 0.2s linear;
		&--0 {
			left: 0;
		}
		&--1 {
			left: 5px;
		}
		&--2 {
			left: 10px;
		}
		&--3 {
			left: 15px;
		}
		&--4 {
			left: 20px;
		}
	}
	&--stop {
		.music-control__bar--0 { transform: scaleY(8) !important; }
		.music-control__bar--1 { transform: scaleY(13) !important; }
		.music-control__bar--2 { transform: scaleY(5) !important; }
		.music-control__bar--3 { transform: scaleY(8) !important; }
		.music-control__bar--4 { transform: scaleY(11) !important; }
	}
}
