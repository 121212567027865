
  $total-x: 10;
  $total-y: 10;
  $total: $total-x * $total-y;
  $per: (100% / $total);
  $big-width: 100px;
  $big-height: 100px;

  @keyframes avatar_animation {
    @for $i from 0 to $total - 1 {
      $current-frame: ($i * $per);
      #{$current-frame} {
        background-position: -#{$big-width * ($i%10)} -#{$big-height * (($i/10) - ($i/10)%1)};
      }
    }
  }

  .avatar {
    $self: &;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    &__inr {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
    &__img {
      display: block;
      width: 100%;
      height: 100%;
      background-size: 1000%;
      background-repeat: no-repeat;
      background-position: 0 0;

      animation: avatar_animation #{$total + 1s} steps(1) backwards;
      animation-play-state: paused;
      animation-iteration-count: initial;
      animation-duration: #{$total + 1s};
    }
  }
