

  $total: 24;
  $per: (100% / $total);
  $pc-width: 96em;
  $pc-height: 120em;
  $mo-width: 64em;
  $mo-height: 80em;

  @keyframes minime_animation_pc {
    @for $i from 0 to $total + 1 {
      $current-frame: ($i * $per);
      #{$current-frame} {
        background-position: -#{$pc-width * $i} 0;
        width: $pc-width;
        height: $pc-height;
      }
    }
  }

  @keyframes minime_animation_mo {
    @for $i from 0 to $total + 1 {
      $current-frame: ($i * $per);
      #{$current-frame} {
        background-position: -#{$mo-width * $i} 0;
        width: $mo-width;
        height: $mo-height;
      }
    }
  }

  .minime {
    z-index: 20;
    position: relative;
    &--floated {
      position: absolute;
      inset: 0 0 0 0;
    }
    &--gutters {
      margin-left: calc(var(--gutter));
		  margin-right: calc(var(--gutter));
    }
    &__cover {
      position: relative;
    }
    &__wrap {
      width: $pc-width;
      height: $pc-height;
      .__w600 &{
        width: $mo-width;
        height: $mo-height;
      }
    }
    &__inner {
      position: relative;
      // padding-top: 125%;
      top: 0;
      left: 0;
      width: $pc-width;
      height: $pc-height;
      .__w600 &{
        width: $mo-width;
        height: $mo-height;
      }
      &__img {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 0;
        animation: minime_animation_pc #{$total + 1s} steps(1) backwards;
        animation-play-state: paused;
        animation-iteration-count: initial;
        animation-duration: #{$total + 1s};
        .__w600 & {
          animation: minime_animation_mo #{$total + 1s} steps(1) backwards;
          animation-play-state: paused;
          animation-iteration-count: initial;
          animation-duration: #{$total + 1s};
        }
        &--none {
          display: none;
        }
      }
    }

    &__balloon {
      position: absolute;
      bottom: 100%;
      right: 100%;
      display: flex;
      flex-wrap: wrap;
      opacity: 0;
      &--hello {
        opacity: 1;
      }
      &--right {
        bottom: 100%;
        left: 100%;
        right: auto;
        .tail {
          transform: scaleX(-1);
          bottom: -2px;
          left: -4px;
        }
      }
      &--with-desc {
        /* left: auto;
        right: 94.6%;
        bottom: calc(100% + 77em);
        .__w600 & {
          bottom: calc(100% + 50em);
        }
        &.minime__balloon--right {
          right: auto;
          left: 67em;
          .__w600 & {
            left: 42em;
          }
        } */
      }
      &__text {
        position: relative;
        padding: 8em 10em;
        border-radius: 8em;
        color: #F9F5EF;
        white-space: nowrap;
        background-color: #A6814C;
        text-align: left;
        path {
          fill: #A6814C;
        }

        // COLOR VARIATIONS
        &--col-red {
          background-color: #D2330F;
          path {
            fill: #D2330F;
          }
        }

        &--col-brown {
          background-color: #A6814C;
          path {
            fill: #A6814C;
          }
        }

        &--col-green {
          background-color: #009E60;
          path {
            fill: #009E60;
          }
        }

        .textbox {
          position: relative;
          z-index: 1;
          display: block;
          .balloon-text {
            display: block;
            position: relative;
          }
        }
        .tail {
          position: absolute;
          width: 14px;
          height: 17px;
          bottom: -2px;
          right: -4px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &__desc {
      position: relative;
      width: 100%;
    }
  }

  .wrap {
    &__minime__desc {
      position: relative;
      .minime__wrap {
        position: absolute;
        left: 0;
        bottom: calc(100% - 13px);
        .w1600__ & {
          bottom: calc(100% - 17px);
        }
      }
      .desc-text {
        text-transform: uppercase;
        text-indent: calc(var(--cw2) + var(--gutter));
        .__w600 &{
          text-indent: calc(var(--cw1) + var(--gutter));
        }
        .w1600__ &{
          text-indent: calc(var(--cw1) + var(--gutter));
          // text-indent: var(--cw2);
          // text-indent: calc(var(--cw2) - var(--gutter) - var(--gutter));
        }
        .t--fak {
          .w1600__ & {
            font-size: 18px;
            letter-spacing: -0.04px;
          }
        }
      }
    }
  }

  .g-ml--init {
    .__w600 &{
      margin-left: calc(var(--gutter)/2)
    }
  }
