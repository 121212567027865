.c-layer{
  $self: &;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: opacity .4s ease-in, min-height 0s ease-in 0.5s;
  transition: opacity .4s ease-in, min-height 0s ease-in 0.5s;
  background: #171010;
  z-index: 1002;
  min-height: 0vh;
  overflow: hidden;
  &.privacy-policy {
    width: 100%;
    height: 100%;
    background: #F9F5EF;
    overflow: auto;
  }
  &__cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 0;
    -webkit-transition: min-height 0s ease-in 0.5s;
    transition: min-height 0s ease-in 0.5s;
  }
  &__dim{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.4);
    -webkit-transition: opacity .5s ease-in;
    transition: opacity .5s ease-in;
    z-index: -1;
  }
  &__btn {
    // padding: 1px 5px 0.4px;
    // border-radius: 4px;
    // color: #F9F5EF;
    // background: #171010;
    &-wrap {
      position: fixed;
      top: 14px;
      right: 16px;
      // top: 14em;
      // right: 16em;
      visibility: hidden;
      .t--fak {
        font-size: 18px !important;
        letter-spacing: -0.04px !important;
        .__w600 & {
          font-size: 16px !important;
        }
      }

      z-index: -1;
      .__w600 &{
        top: 14px;
        right: 16px;
      }
    }
    &-close{
      font-size: 0;
      padding: 0px 4px;
      border-radius: 4px;
      color: #F9F5EF;
      background: #171010;
      .t--fak {
        line-height: 1 !important;
      }
      &--w{
        color: #171010;
        background: #F9F5EF;
      }
      .__w600 & {
        padding: 3px 5px 2px 5px;
      }
    }
  }

  &--open{
    opacity: 1;
    height: 100%;
    // min-height: 100vh;
    z-index: 1400;
    -webkit-transition: opacity .5s ease-in;
    transition: opacity .5s ease-in;
    #{$self}__dim {
      opacity: 1;
      z-index: 2;
    }
    #{$self}__btn-wrap {
      z-index: 50;
      visibility: visible;
    }
    #{$self}__cont {
      min-height: 100vh;
      -webkit-transition: min-height 0s ease-in 0.5s;
      transition: min-height 0s ease-in 0.5s;
    }
  }
  &--youtub {
    #{$self}__btn-wrap {
      // top: 14px;
      // right: 16px;
      // .t--fak {
      //   font-size: 18px !important;
      // }
      // .__w600 & {
      //   top: 14px;
      //   right: 16px;
      // }
    }
    #{$self}__cont {
      width: 100%;
      min-height: auto;

      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      top: 48px;
      bottom: 48px;
    }
  }
}

.c-link{color: #A6814C;}

.c-logo{
  position: relative;
  width: 240em;
  margin: 0 auto;
  svg {
    width: 100%;
    fill: #EBE3D7
  }
  &--dfy{
    display: inline-block;
    width: 100%;
  }
  &--r{
    display: inline-block;
    top: 0;
    right: -13em;
    position: absolute;
    width: 13em;
  }
  .w1600__ &{
    width: 240em;
  }
  .w1600__ &--r{
    width: 9em;
    right: -11em;
  }
  .__w600 & {
    width: 160em;
  }
}
.round-txt{
  display: inline-block;
  width: 1.95em;
}
.row-blt{
  position: relative;
  padding-top: 80em;
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 13em;
    height: 13em;
    background: url('~@/assets/work/dfy_work_archive-title-mark_w.svg') no-repeat;
    background-size: 100%;
  }
}
