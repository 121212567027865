
.header {
	// opacity: 0.2;
	// z-index: -1 !important;
	position: fixed;
	left: var(--outerGutter);
	right: var(--outerGutter);
	top: 16px;
	height: 0;
	z-index: 1300;
	transition: transform 0.5s ease;
	// &__audio-control {
	// 	position: absolute;
	// 	right: 100%;
	// 	top: 0;
	// }
	&__logo-wrap {
		position: absolute;
		top: 0;
		left: 0;
	}
	&__logo {
		display: inline-block;
		path {
			fill: var(--foreground);
			transition: fill 0.3s;
		}
	}
	&__right {
		position: absolute;
		display: inline-block;
		right: 0;
		top: -2px;
	}
	&__nav-item {
		display: inline-block;
		&:first-child {
			.header__nav-lnk {
				margin-left: 0;
			}
		}
		&--active {
			.header__nav-lnk {
				color: var(--background);
				background-color: var(--foreground);
			}
		}
	}
	&__nav-lnk {
		display: inline-block;
		padding: 2px 5px;
		margin-left: 16px;
		border-radius: 4px;
		font-family: "Faktum";
		text-transform: uppercase;
		color: var(--foreground);
		font-weight: 400;
		font-size: 18px;
		letter-spacing: -0.04em;
		line-height: 1;
		vertical-align: middle;
		transition: color 0.3s, background-color 0.3s;
	}
	&__nav {
		display: flex;
		position: relative;
		&--over {
			.header__nav-lnk {
				color: var(--foreground);
				background-color: transparent;
				&:hover {
					color: var(--background);
					background-color: var(--foreground);
				}
			}
		}
	}
	&--hide {
		transform: translate(0, -50px);
		transition: transform 0.3s ease;
	}
}
