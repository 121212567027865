
.copy {
  padding-bottom: 32em;;
  &-tit {
    position: relative;
    .__w600 & {
      display: flex;
      flex-wrap: wrap;
    }
    &__unit {
      font-family: "Editorial";
      font-weight: 300;
      font-size: 189em;
      line-height: 0.94;
      margin-left: 0.02em;
      letter-spacing: -0.06em;
      
      &:first-child {
        margin-left: 0.07em;
      }
      &.the {
        font-family: "Editorial Italic";
        letter-spacing: -0.068em;
        margin-left: -0.037em;

      }
      &.tm {
        position: absolute;
        top: 1.1em;
        right:1.6em;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 9.6em;
        letter-spacing: -0.02em;

      }
      .__w600 & {
        font-size: 144em;
        &:first-child {
          letter-spacing: -0.08em;
        }
        &.tm {
          font-size: 9.6em;
          top: 11.1em;
        }  
        &.the {
          margin-top: 0.18em;
          letter-spacing: -0.09em;
        }
        &--last {
          margin-top: -0.33em;
          margin-left: 0.08em;
        }
      }
      .w1600__ & {
        font-size: 299em;
        &:first-child {
          margin-left: 0.035em;
        }
        &.tm {
          font-size: 9.6em;
          top: 2.1em;
        }
      }
    }
  }
}
  .slogan {
    margin-top: -18em;
    &__keyword {
      display: flex;
      position: absolute;
      top: 0;
      left: 299em;
      align-items: center;
      &-wrap {
        white-space: nowrap;
        .__w600 & {
          display: block;
        }
      }
      &-unit {
        text-transform: lowercase;
      }
      .__w600 &{
        display: block;
        text-align: center;
        position: relative;
        left: 0;
        transform: translateX(0%);
      }
      .w1600__ &{
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__ripple {
      padding: 0;
      &--1 {
        margin-left: 19em;
        .__w600 & {
          margin-left: 0;
        }
      }
      &--2 {
        margin-left: 8em;
      }
      .__w600 & {
        margin-top: 8em;
      }
    }
    .__w600 &{
      margin-top: -14em;
    }
    .w1600__ & {
      margin-top: -22em;
    }
  }
  .dfy-logo-box {
    display: block;
    align-self: flex-end;
    .__w600 & {
      position: absolute;
    }
  }
