
.c-noti {
	position: fixed;
	display: none;
	right: 43px;
	bottom: 30px;
	width: 175px;
	font-family: "YoonGothicPro750";
	word-break: keep-all;
	text-align: left;
	color: #F9F5EF;
	z-index: 1490;
	transform: translateY(0);
	opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
	&__item {
		position: relative;
		margin-top: 10px;
		padding: 12px 16px;
		background-color: #0AA86C;
		border-radius: 16px 16px 2px 16px;
	}
	&__close-btn {
		position: absolute;
		right: 8px;
		top: 8px;
		font-size: 0;
		&:hover {
			path {
				fill-opacity: 0.2;
			}
		}
		path {
			transition: fill-opacity 0.15s;
		}
	}
	&__btn {
		position: relative;
		display: none;
		font-size: 13px;
		line-height: 1.48;
		letter-spacing: -0.02em;
		padding: 4px 32px 4px 12px;
		margin-top: 9px;
		border-radius: 28px;
		background-color: rgba(23, 16, 16, 0.1);
		font-family: inherit;
		transition: background-color 0.15s;
		&:hover {
			background-color: rgba(23, 16, 16, 0.2);
		}
	}
	&__btn-ico {
		position: absolute;
		top: 50%;
		right: 4px;
		transform: translateY(-50%);
	}
	&__item-text {
		font-size: 13px;
		line-height: 1.48;
		letter-spacing: -0.02em;
	}
	&--play {
		display: block;
	}
	&--hide {
		transform: translateY(70px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.23s ease;
	}
	.s--in-admin &, [data-page-name="404"] & {
		display: none !important;
	}
}
