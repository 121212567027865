
@import "@/css/_settings.scss";
  .channel-map-info {
    position: absolute;
    bottom: $gutter;
    left: $gutter;
    width: calc(100% - #{$gutter} - #{$gutter});
    padding: 13em 15em;
    border-radius: 16px;
    background: $f-white;
    .indicate {
      &__upper {
        display: flex;
        .clock {
          $clockDiameter: 39em;
          $innerDiameter: $clockDiameter * 0.8;

          position: relative;
          width: $clockDiameter;
          height: $clockDiameter;
          border: 1px solid $f-black;
          border-radius: 50%;
          &__inner {
            position: absolute;
            top: ($clockDiameter - $innerDiameter)/2;
            right: ($clockDiameter - $innerDiameter)/2;
            width: $innerDiameter;
            height : $innerDiameter;
            border-radius: 50%;
          }
          &__neddles {
            position: absolute;
            left: 0;
            height: 1em;
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            &--hour {
              top: $innerDiameter/2 - ($clockDiameter/50);
              left: 15%;
              width: 35%;
              background: $f-black;
              animation-name: rotateHour;
              animation-duration: 143200s;
              transform-origin: 100% 50%
            }
            &--min {
              top: $innerDiameter/2 - ($clockDiameter/80);
              width: 50%;
              background: $f-black;
              animation-name: rotateMin;
              animation-duration: 3600s;
              transform-origin: 100% 50%
            }
            &--sec {
              top: $innerDiameter/2 - ($clockDiameter/150);
              width: 60%;
              z-index: 4;
              background-color: $f-red;
              animation-name: rotateSec;
              animation-duration: 60s;
              transform-origin: 83.3333% 50%;
            }
          }
          &__center-circle {
            background-color: $f-red;
            position: absolute;
            top: calc(50% - 2em);
            left: calc(50% - 2em);
            width: 4em;
            height: 4em;
            border-radius: 50%;
            z-index: 10;
          }
        }
        .date {
          padding: 0 0 13em 16em;
          &__item {
            &--info {
              opacity: .4;
            }
          }
        }
        .weather {
          opacity: .4;
        }
        .thermometer {
          text-align: right;
          margin-left: auto;
        }
      }
      &__lower {
        position: relative;
        padding-top: 13em;
        &:before {
          content:'';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $f-black;
          opacity: 0.1;
        }
        .address {
          display: flex;
          align-items: center;
          width: 100%;
          &__text {
            opacity: .4;
          }
          &__button {
            margin-left: auto;
            padding: 0.5em 1.2em;
            border-radius: 15px;
            background-color: #007AFF;
            color: $f-white;
          }
        }
      }
    }
  }
