
  // @import "@/css/_settings.scss";
  .normal-desc {
    &__kr {
      margin-top: 0.52em;
    }
  }
  .t-dfy {
    color: inherit;
    &:after {
      color: inherit;
    }
  }
