@import "_settings";

$cb-black: #171010; // black
$cb-white: #F9F5EF; //spring wood
$cb-beige: #EBE3D7; //satin linen
$cb-coffee: #A6814C; //coffee
$cb-blue: #007AFF; // blue
$cb-green: #34C759; // green
$cb-yellow: #F6CA74; // yellow
$cb-red: #FF3B30; // red


.t-cb {
	@at-root [class*="t-cb"] {
		font-family: "Faktum";
		font-weight: 400;
		font-feature-settings: 'case' on, 'salt' on;
	}
  &__item {
    display: block;
    // text-indent: 4em;
    text-indent: 5.4em;
  }

  &--11 {
    [class*="t--"] {
      font-size: 11em;
      line-height: 1.25;
      letter-spacing: -0.02em;
    }
  }
  &--13 {
    [class*="t--"] {
      font-size: 13em;
      line-height: 1.25;
      letter-spacing: -0.02em;
    }
    .t--kr {
      font-size: 11.05em;
      line-height: 1.409;
    }
  }
  &--16 {
    [class*="t--"] {
      font-size: 16em;
      line-height: 1.25;
      letter-spacing: -0.02em;
    }
    .t--kr {
      font-size: 13.2em;
      line-height: 1.48;
    }
    &-s {
      [class*="t--"] {
        .__w600 & {
          font-size: 16em;
          line-height: 1.25;
          letter-spacing: -0.02em;
        }
      }
    }
  }
  &--18 {
    [class*="t--"] {
      font-size: 18em;
      line-height: 1.25;
      letter-spacing: -0.04em;
    }  
    .t--kr {
      font-size: 14.5em;
      line-height: 1.56;
    }
  }
  &--24 {
    [class*="t--"] {
      font-size: 24em;
      line-height: 1.16;
      letter-spacing: -0.04em;
    }
    .t--kr {
      font-size: 24em;
    }
  }
  &--28 {
    [class*="t--"] {
      font-size: 28em;
      line-height: 1.16;
      letter-spacing: -0.04em;
    }
    .t--kr {
      font-size: 22.6em;
      line-height: 1.4;
    }
  }
  &--30 {
    [class*="t--"] {
      font-size: 30em;
      line-height: 1.16;
      letter-spacing: -0.04em;
    }  
    .t--kr {
      font-size: 23.8em;
    }
  }
}
.cb-scroll--none {
  pointer-events: none;
}

.chat-module {
  margin: 30px;
  margin-top: 20px;
  .t-h--18 {
    display: inline-block;
    margin-bottom: 20px;
    border-bottom: 2px solid darkcyan;
  }
  &-tit {
    display: inline-block;
    margin-left: 30px !important;
    margin-top: 30px;
  }
  &-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
  }
}
.overlay {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 16em;
  background-color: $cb-black;
  opacity: 0;
}
.b-hover {
  $self: &;
  &--white {
    background: rgba(249, 245, 239, 0.4);
    &:hover {
      background: rgba(249, 245, 239, 0.8);
      transition: background 0.25s ease;
    }
    #{ $self}__icon {
      &.ico-chatbot--navi-navigate--back::before, &.ico-chatbot--media-videocam::before, &.ico-chatbot--content-place::before {
        color: $cb-black;
      }
    }
  }
  &--black {
    background: rgba(23, 16, 16, 0.1);
    &:hover {
      background: rgba(23, 16, 16, 0.2);
      transition: background 0.25s ease;
    }
    #{ $self}__icon {
      &.ico-chatbot--navi-navigate--back::before, &.ico-chatbot--media-videocam::before, &.ico-chatbot--content-place::before {
        color: $cb-white;
      }
    }
  }
}

// item
.emoji {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 0.2em;
  margin-top: -0.1em;
}
.person {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: $em32;
  &__face {
    width: 40em;
    height: 40em;
    border-radius: 50%;
    overflow: hidden;
    &-wrap {
      position: relative;
    }
  }
  &__state {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12em;
    height: 12em;
    border-radius: 50%;
    background-color: $cb-green;
    border: 2px solid $cb-white;
    &--green {
      background-color: $cb-green;
    }
    &--blue {
      background-color: $cb-blue;
    }
    &--black {
      background-color: $cb-black;
    }
  }
  &__txt {
    margin-left: $em8;
  }
  &__info {
    opacity: 0.6;
  }
  &__location-img {
    font-size: 12em;
  }
  &__location {
    margin-left: 0.1em;
  }
}
.sec-tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__num {
    width: 22em;
    height: 24em;
    border-radius: 10em;
    padding: 2em 6em;
    background-color: rgba(23, 16, 16, 0.05);
    text-align: center;
  }
  &__label {
    opacity: 0.4;
  }
}
.sec-subtit {
  display: flex;
  justify-content: space-between;
  &__label-wrap {
    display: flex;
    align-items: center;
    opacity: 0.6;
  }
}
.b-border {
  position: relative;
  display: inline-block;
  height: 24em;
  padding-left: 23em;
  padding-right: $em8;
  border: 1px solid rgba(23, 16, 16, 0.2);
  &:hover {
    border-color: rgba(23, 16, 16, 0.4);
  }
  &__img {
    position: absolute;
    display: inline-block;
    font-size: 16em;
    left: 0.5em;
    top: 50%;
    transform: translateY(-50%);
  }
  &__txt {
    margin-left: 3em;
  }
  &--square {
    border-radius: 6em;
  }
  &--ellipse {
    border-radius: 12em;
  }
}
.b-restart {
  display: flex;
  align-items: flex-end;
  opacity: 0.6;
  &:hover {
    opacity: 0.4;
  }
  &__img {
    display: inline-block;
    font-size: 16em;
  }
  &-wrap {
    display: flex;
    align-items: flex-end;
  }
}
.b-circle {
  position: relative;
  border-radius: 50%;
  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.b-back {
  position: relative;
  border-radius: 50%;
  width: 40em;
  height: 40em;
  backdrop-filter: blur(20px);
  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 7.58em;
    height: 13.2em;
  }
}
.b-arrow {
  $self: &;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  width: 20em;
  height: 20em;
  background-color: rgba(23, 16, 16, 0.05);
  // &:hover {
  //   background-color: rgba(23, 16, 16, 0.1);
  //   transition: all 0.25s ease;
  //   #{$self} {
  //     &__img {
  //       &--black {
  //         &.ico-chatbot--navi-arrow--se::before, &.ico-chatbot--navi-arrow--ne::before {
  //           opacity: 0;
  //           transition: all 0.25s ease;
  //         }
  //       }
  //       &--white {
  //         &.ico-chatbot--navi-arrow--se::before, &.ico-chatbot--navi-arrow--ne::before {
  //           opacity: 0.8;
  //           transition: all 0.25s ease;
  //         }
  //       }
  //     }
  //   }
  // }
  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &--black {
      &.ico-chatbot--navi-arrow--se {
        font-size: 11.2em;
        &::before {
          color: $cb-black;
          opacity: 0.4;
        }
      }
      &.ico-chatbot--navi-arrow--ne {
        font-size: 11.2em;
        &::before {
          color: $cb-black;
          opacity: 0.4;
        }
      }
    }
    &--white {
      &.ico-chatbot--navi-arrow--se {
        font-size: 11.2em;
        &::before {
          color: $cb-white;
          opacity: 0;
          // opacity: 0.8;
        }
      }
      &.ico-chatbot--navi-arrow--ne {
        font-size: 11.2em;
        &::before {
          color: $cb-white;
          opacity: 0;
        }
      }
    }
  }
}
.b-sound {
  $self: &;
  width: 28em;
  height: 28em;
  border-radius: 50%;
  background-color: rgba(23, 16, 16, 0.1);
  backdrop-filter: blur(20px);
  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &--off {
      opacity: 1;
    }
    &--on {
      opacity: 0;
    }
  }
  &--on {
    #{$self}__img {
      &--off {
        opacity: 0;
      }
      &--on {
        opacity: 1;
      }
    }
  }
}
.b-icon {
  position: relative;
  border-radius: 50%;
  width: 32em;
  height: 32em;
  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.b-lang {
  position: relative;
  border-radius: 50%;
  width: 32em;
  height: 32em;
}

$head-duration: 0.25s;
.head {
  $self: &;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
  width: 100%;
  height: 0em;
  background-color: transparent;
  &__inr {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: all;
    &--default {
      #{$self} {
        &__tit {
          &-wrap {
            opacity: 0;
          }
        }
        &__btn {
          margin-left: 6em;
          margin-right: 6em;
          width: 40em;
          height: 40em;
          backdrop-filter: blur(20px);
          &-wrap {
            position: absolute;
            display: flex;
            align-items: center;
            right: $em16;
            top: $em16;
            transform: translate(0%, 0%);
            margin-left: -6em;
            margin-right: -6em;
          }
          &--back {
            position: absolute;
            left: $em16;
            top: $em16;
            margin-left: 0em;
            margin-right: 0em;
          }
        }

      }
    }
    &--scroll {
      height: 48em;
      background-color: rgba(249, 245, 239, 0.8);
      backdrop-filter: blur(20px);
      transform: translateY(-100%);
      opacity: 0;
      #{ $self } {
        &__tit {
          &-wrap {
            position: absolute;
            display: flex;
            align-items: center;
            white-space: nowrap;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &__img {
          display: inline-block;
          position: relative;
          width: 24em;
          height: 24em;
          border-radius: 50%;
          margin-right: 4em;
          overflow: hidden;
        }
        &__btn {
          margin-left: 2em;
          margin-right: 2em;
          width: 32em;
          height: 32em;
          transition: all $head-duration ease;
          background-color: transparent;
          color: $cb-black !important;
          &:hover {
            background: rgba(23, 16, 16, 0.05);
          }
          &-wrap {
            position: absolute;
            display: flex;
            align-items: center;
            right: 16em;
            top: 50%;
            transform: translate(0%, -50%);
            margin-left: -2em;
            margin-right: -2em;
          }
          &--back {
            position: absolute;
            left: $em4;
            top: 50%;
            transform: translate(0%, -50%);
            margin-left: 0em;
            margin-right: 0em;
          }
        }
      }
      .b-hover__icon {
        &.ico-chatbot--navi-navigate--back::before, &.ico-chatbot--media-videocam::before, &.ico-chatbot--content-place::before {
          color: $cb-black;
        }
      }
    }  
  }
  &__icon {
    font-size: 24em;
  }
  &--scroll {
    #{$self}__inr {
      &--default {
        display: none;
      }
      &--scroll {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s ease;
      }
    }
  }
}
.head-out {
  $self: &;
  position: relative;
  width: 390em;
  height: 28em;
  background-color: $cb-white;
  border-radius: 10em 10em 0em 0em;
  border-bottom: 1px solid $cb-beige;
  &__tit {
    &-wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__traf-btn {
    position: relative;
    width: 12em;
    height: 12em;
    border-radius: 50%;
    border: 1px solid $cb-beige;
    margin-left: 5.5em;
    margin-right: 5.5em;
    &:hover {
      #{$self}__close {
        display: block;
      }
    }
    &--close {
      border: none;
      background-color: $cb-red;
    }
    &-wrap {
      display: flex;
      align-items: center;
      position: absolute;
      left: $em8;
      top: 50%;
      transform: translate(0%, -50%);
      margin-left: -5.5em;
      margin-right: -5.5em;
      .__w600 & {
        margin-left: -2em;
        // margin-left: -4em;
        margin-right: -4em;
      }
    }
    .__w600 & {
      width: 20em;
      height: 20em;
      margin-left: 4em;
      margin-right: 4em;
    }
  }
  &__close {
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6em;
    .__w600 & {
      width: 10em;
      display: block;
    }
  }
  .__w600 & {
    height: 40em;
  }
}

.balloon {
  $self: &;
  &__box {
    display: inline-block;
    position: relative;
    max-width: 269em;
    padding-right: $em16;
    padding-left: $em16;
    padding-top: 12em;
    padding-bottom: 12em;
    margin-top: 4em;
    border-radius: 2em 16em 16em 16em;
    background-color: $cb-beige;
    word-break: keep-all;
    &--loading {
      padding: 17em 16em;
    }
    &--user {
      margin-top: $em4;
      border-radius: 16em 2em 16em 16em;
      background-color: $cb-coffee;
      color: $cb-white;
    }
    &--user-submit {
      width: 358em;
      max-width: 100%;
      // max-width: 358em;
      height: 96em;
      padding: 30.5em 50em;
      text-align: center;
      .t-cb--16 .t--kr {
        font-size: 22.6em;
      }
      .t-cb--16 .t--fak {
        font-size: 28em;
      }

    }
    // &--submit {
    //   width: 358em;
    //   max-width: 100%;
    //   // max-width: 358em;
    //   height: 96em;
    //   padding: 30.5em 50em;
    //   margin-top: $em4;
    //   border-radius: 16em 2em 16em 16em;
    //   text-align: center;
    //   background-color: $cb-coffee;
    //   color: $cb-white;
    // }
    &--btn {
      border: 2px solid #EBE3D7;
      border-radius: 16em 2em 16em 16em;
      box-sizing: border-box;
      background-color: $cb-white;
      color: $cb-black;
      padding: 10em 14em;
    }
    &--submit {
      width: 358em;
      max-width: 100%;
      // max-width: 358em;
      height: 96em;
      padding: 30.5em 20em;
      border-radius: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 8em;
      // transition: all 0.1s ease;
      &:hover {
        color: $cb-coffee;
        border-color: $cb-coffee;
      }
    }
    &--sub-complete {
      width: 358em;
      max-width: 100%;
      // max-width: 358em;
      height: 96em;
      padding: 30.5em 50em;
      border-radius: 16px;
      border: none;
      margin-left: auto;
      margin-right: auto;
      margin-top: 8em;
      background-color: $cb-coffee;
      color: $cb-white;
      opacity: 1;
      &-hide {
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      .balloon__dot {
        display: inline-block;
      }
    }
    &--confirm {
      display: flex;
      border-radius: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 32em;
      transition: all 0.15s ease;
      .b-confirm__arrow {
        transition: all 0.15s ease;
      }
      &:hover {
        border-color: $cb-coffee;
        color: $cb-coffee;
        .b-confirm__arrow {
          background: $cb-coffee;
          backdrop-filter: blur(20px);
          .ico-chatbot--content-done {
            &::before {
              color: $cb-white;
              opacity: 0.8;
            }
          }
        }
      }
      &-hide {
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }
  }
  &__txt {
    &--submit {
      transform: translateY(0em);
      opacity: 1;
      #{$self}__dot {
        width: 0.1em;
        height: 0.1em;
        background-color: $f-white;
        &:nth-child(2) {
          margin-left: 0.1em;
          margin-right: 0.1em;
        }
      }
    }
    &--complete {
      transform: translateY(-10%);
      opacity: 0;
    }
  }
  &__txt {
    &--sub-com {
      #{$self}__txt {
        &--submit {
          transform: translateY(-95%);
          opacity: 0;
          transition: all 0.5s ease;
        }
        &--complete {
          transform: translateY(-95%);
          opacity: 1;
          transition: all 0.5s ease;
        }
      }
    }
  }
  &__img {
    width: 11.5em;
    margin-right: 2em;
  }
  &__date-wrap {
    margin-top: $em32;
    text-align: right;
    opacity: 0.4;
  }
  &__dot {
    display: none;    
    width: 4em;
    height: 4em;
    margin-left: 3em;
    border-radius: 50%;
    background-color: $cb-white;
    animation: balloon-bounce 1s ease-in-out infinite both;
    animation-delay: 0s;
    &--1 {
      animation-delay: -0.2s;
    }
    &--2 {
      // margin-right: 0.1em;
    }
    &--3 {
      animation-delay: 0.25s;
    }
  }
  &__react {
    position: absolute;
    left: -16em;
    top: -16em;
    width: 32em;
    transform-origin: 50% 50%;
    opacity: 0;
    &--show {
      opacity: 1;
      animation: react 0.4s ease 1;
    }
  }
}
@keyframes react {
  0%   {transform: rotate(+40deg) scale(0); opacity: 0;}
  70%  {transform: rotate(-10deg) scale(1.2)}
  100% {transform: rotate(0deg) scale(1); opacity: 1;}
}


.p-balloon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16em;
  padding-right: 21em;
  padding-top: 12em;
  padding-bottom: 12em;
  margin-top: $em8;
  border-radius: 2em 16em 16em 16em;
  width: 253em;
  background-color: $cb-blue;
  color: $cb-white;
  &__tit {
    text-align: left;
  }
  &__txt {
    text-align: left;
    opacity: 0.8;
  }
  &__img {
    .ico-chatbot--content-lock::before {
      font-size: 32em;
      color: $cb-white;
    }
  }
}

.balloon2 {
  &__dot {
    transform: translateY(2px);
    &:first-child {
      animation-delay: -0.125s;
    }
    &:last-child {
      animation-delay: 0.125s;
    }
  }

}
@keyframes balloon-bounce {
  50% {transform:translateY(-5px)}
}

.b-balloon {
  display: inline-block;
  &:last-child {
    margin-right: 0;
  }
  &__check {
    display: none;
    &:checked + .b-balloon__box {
      border: 2px solid $cb-coffee;
      color: $cb-coffee;
    }
  }
  &__box {
    display: inline-block;
    border: 2px solid $cb-beige;
    border-radius: 16em;
    margin-top: 8px;
    &:hover:enabled {
      .w600__ & {
        border: 2px solid $cb-coffee;
        color: $cb-coffee;
      }
    }
    &:disabled {
      opacity: 0.4;
    }
    &--active {
      border: 2px solid $cb-coffee;
      color: $cb-coffee;
    }
    &-inr {
      cursor: pointer;
      display: block; 
      height: 100%;
      padding: 10em 14em;
    }
  }
}
.b-confirm {
  $self: &;
  display: flex;
  padding: 12px 16px;
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32em;
  background: $cb-beige;
  transition: all 0.15s ease;
  &__arrow {
    margin-left: 8em;
    background-color: rgba(23, 16, 16, 0.05);
    .ico-chatbot--navi-arrow--forward, .ico-chatbot--content-done {
      font-size: 14em;
      &::before {
        color: $cb-black;
        opacity: 0.4;
      }
    }
  }
  &:hover {
    background: $cb-coffee;
    color: $cb-white;
    #{$self}__arrow {
      background-color: rgba(23, 16, 16, 0.1);
      .ico-chatbot--navi-arrow--forward, .ico-chatbot--content-done {
        &::before {
          color: $cb-white;
          opacity: 0.8;
        }
      }
    }
  }
}
.in-box {
  $self: &;
  margin-top: 14em;
  // max-width: 358em;
  max-width: 100%;
  &:first-child {
    margin-top: 0;
  }
  &-wrap {
    display: inline-block;
    margin-top: 16em;
    max-width: 100%;
  }
  &__box {
    font-family: faktum;
    font-weight: 400;
    font-size: 16em;
    width: calc(358em/16);
    height: calc(44em/16);
    max-width: 100%;
    padding-right: calc(35em/16);
    padding-left: 1em;
    border: 2px solid $cb-beige;
    box-sizing: border-box;
    border-radius: 1em;
    background-color: $cb-white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::placeholder {
      line-height: 1.25;
      letter-spacing: -0.02;
    }
    &:hover {
      border: 2px solid $cb-coffee;
    }
    &:focus {
      border: 2px solid $cb-coffee;
      + #{$self}__state {
        display: none;
      }
      ~ #{$self}__close {
        width: 30em;
        height: 30em;
        opacity: 0.4;
        cursor: pointer;
      }
      + #{$self}__select-arrow {
        opacity: 1;
      }
    }
    + #{$self}__select-arrow {
      opacity: 0.4;
    }
    &--posi {
      position: relative;
      &:focus {
        border: 2px solid #009E60;
        background-color: rgba(0, 158, 96, 0.05);
      }
      + #{$self}__state {
        &::after {
          content: '';
          position: absolute;
          width: 20em;
          height: 20em;
          top: 50%;
          right: 12em;
          transform: translateY(-50%);
          background: url('../assets/chatbot/content-done.svg') no-repeat;
          background-size : cover;
        }
        + #{$self}__icon {
          display: none;
        }
      }
      #{$self}__select-arrow {
        display: none;
      }
    }
    &--nega {
      position: relative;
      &:focus {
        border: 2px solid #D2330F;
        background-color: rgba(210, 51, 15, 0.05);
      }
      + #{$self}__state {
        &::after {
          content: '';
          position: absolute;
          width: 20em;
          height: 20em;
          top: 50%;
          right: 12em;
          transform: translateY(-50%);
          background: url('../assets/chatbot/content-clear.svg') no-repeat;
          background-size : cover;
        }
        + #{$self}__icon {
          display: none;
        }
      }
      #{$self}__select-arrow {
        display: none;
      }
    }
    &--preset {
      pointer-events: none;
    }
    &--long {
      height: calc(164em/16);
      padding-top: calc(12em/16);
      padding-bottom: calc(12em/16);
      resize: none;
      + #{$self}__state {
        &::after {
          top: 11em;
          transform: translateY(0%);
        }
      }
      &:focus {
        + #{$self}__state {
          &::after {
            top: 13.6em;
            transform: translateY(0%);
          }
        }
      }
      ~ .in-box__close {
        top: 6em;
        transform: none;
      }

    }
    &--selected {
      line-height: calc(40em/16);
      .t-cb--16 {
        font-size: 1px;
      }
    }
    &-wrap {
      position: relative;
      display: inline-block;
      margin-top: $em4;
      max-width: 100%;
    }
    &--file {
      width: 0;
      height: 0;
      &-wrap {
        width: 358em;
        height: 44em;
        max-width: 100%;
        label:hover {
          cursor: pointer;
        }
        .in-box__box {
          width: 100%;
          height: 100%;
          display: inline-block;
          line-height: 2.4em;
          color: rgba(23, 16, 16, 0.6);
        }
      }
    }
    select:hover {
      cursor: pointer;
    }
  }
  &__close {
    position: absolute;
    opacity: 0;
    top: 50%;
    right: 6em;
    transform: translateY(-50%);
    cursor: default;
    .ico-chatbot--content-cancel {
      font-size: 20em;
    }
  }
  &__statetest {
    position: absolute;
    top: 50%;
    right: 0.65em;
    transform: translateY(-50%);
    font-size: 20em;
    display: none;
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 0.65em;
    transform: translateY(-50%);
    &.ico-chatbot--content-file {
      font-size: 20em;
      opacity: 0.4;
    }  
  }
  &__select {
    appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    &-arrow {
      position: absolute;
      top: 50%;
      right: 0.65em;
      transform: translateY(-50%);
      &.ico-chatbot--navi-expand--more {
        font-size: 1.25em;
        opacity: 0.4;
      }
    }
  }
  &__label {
    &--soft {
      opacity: 0.4;
    }
    &--required {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -7em;
        width: 4em;
        height: 4em;
        border-radius: 50%;
        background-color: $cb-red;
      }
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &--disabled {
    opacity: 0.4;
    #{$self}__box:hover {
      border-color: $cb-beige;
    }
  }
}
.policy-check {
  $self: &;
  margin-top: 16em;
  &__box {
    position: relative;
    display: inline-block;
    width: 20em;
    height: 20em;
    border: 2px solid #EBE3D7;
    box-sizing: border-box;
    border-radius: 4em;
  }
  input[id="policy"] {
    display: none;
    &:hover {   
      + #{$self}__label {
        #{$self}__box {
          border-color: $cb-coffee;
        }
        .ico-chatbot--input-check::before {
          color: $cb-coffee;
        }
      }
    }
    &:checked {
      + #{$self}__label {
        #{$self}__box {
        border-color: $cb-coffee;
          background-color: $cb-coffee;
        }
        .ico-chatbot--input-check::before {
          color: $cb-white;
        }
      }
    }
  }
  &__label {
    display: flex;
  }
  &__txt {
    margin-left: 8em;
  }
  &__page {
    color: $cb-coffee;
    text-decoration: underline;
  }
  .ico-chatbot--input-check {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 8.68em;
  }
  &.in-box__box--nega {
    #{$self}__box {
      border-color: $cb-red;
      background-color: rgba(255, 56, 48, 0.05);
      .ico-chatbot--input-check::before {
        color: $cb-beige;
      }
    }
  }
}
.gip {
  display: block;
  width: 285em;
  margin-top: $em8;
  &__img {
    border-radius: 0em 16em 0em 0em;
    overflow: hidden;
  }
  &__info-wrap {
    padding-top: 12em;
    padding-bottom: 12em;
    padding-right: $em16;
    padding-left: $em16;
    border-radius: 0em 0em 16em 16em;
    background-color: $cb-beige;
  }
  &__txt {
    &--soft {
      opacity: 0.4;
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
  }
}

// main
.card {
  $self: &;
  position: relative;
  width: 358em;
  margin-top: 24em;
  border-radius: 16em;
  text-align: left;
  &:hover {
    .overlay {
      opacity: 0.05;
      transition: opacity 0.25s ease;
    }
  }
  &:nth-child(1) {
    margin-top: 0;
  }
  &__bg {
    &-wrap {
      width: 100%;
      border-radius: 16em;
      overflow: hidden;
    }
  }
  &__shape {
    &-wrap {
      position: absolute;
      width: 91.0614525%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &--float1 {
        width: 100%;
        height: 100%;
        #{$self}__shape {
          // will-change: transform;
          &-inr {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
          &--1 {
            position: absolute;
            width: 44%;
            top: 102em;
            left: 5em;
            transform: translate(0%, 0%) rotate( -67deg );
            // animation: card-shape1-1 5s ease-in-out infinite;
            z-index: 1;
          }
          &--2 {
            position: absolute;
            width: 44.4%;
            top: 54.3em;
            left: 160.3em;
            transform: translate(0%, 0%) rotate( 15deg );
            // animation: card-shape1-2 5s ease-in-out infinite;
            z-index: 2;
          }
          &--3 {
            position: absolute;
            width: 43.9%;
            top: 155.9em;
            left: 166.1em;
            transform: translate(0%, 0%) rotate( 35deg );
            // animation: card-shape1-3 5s ease-in-out infinite;
            z-index: 3;
          }
        }
      }
      &--float2 {
        width: 100%;
        height: 100%;
        #{$self}__shape {
          // will-change: transform;
          &-inr {
            position: relative;
            width: 100%;
            height: 100%;
          }
          &--1 {
            position: absolute;
            width: 43%;
            top: 57.3em;
            left: 20em;
            transform: translate(0%, 0%) rotate( -30deg );
            // animation: card-shape2-1 5s ease-in-out infinite;
          }
          &--2 {
            position: absolute;
            width: 43%;
            top: 68em;
            left: 184em;
            transform: translate(0%, 0%) rotate( 50deg );
            // animation: card-shape2-2 5s ease-in-out infinite;
          }
          &--3 {
            position: absolute;
            width: 43%;
            top: 184em;
            left: 109em;
            transform: translate(0%, 0%) rotate( 11deg );
            // animation: card-shape2-3 5s ease-in-out infinite;
          }
        }
      
      }
    }
  }
  &__tit {
    margin-top: $em4;
    word-break: keep-all;
    &--w200 {
      width: 200em;
    }
    &--w250 {
      width: 250em;
    }
    &-wrap {
      position: absolute;
      top: 13em;
      left: $em16;
      right: $em16;
    }
  }
  &__location {
    opacity: 0.6;
  }
  &__face {
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    &-video {
      width: 100%;
    }
    &-wrap {
      position: relative;
      width: 54em;
      height: 54em;
      margin-bottom: 2.6em;
    }
  }
  &__state {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16em;
    height: 16em;
    border-radius: 50%;
    background-color: $cb-green;
    border: 2px solid $cb-white;
  }
  &__label {
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  &__desc {
    margin-top: 0em;
    word-break: keep-all;
    &-wrap {
      position: absolute;
      bottom: 13em;
      left: $em16;
      right: $em16;
    }
    &-inr {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &--bot {
      width: 260em;
    }
    &--line-limit {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; 
      -webkit-box-orient: vertical;
    }
  }
  &--with-pad {
    .card__pad {
      position: relative;
      padding-top: 100%;
    }
    .card__bg-wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
@keyframes card-shape1-1 {
  0%   {transform: translate(0%, 0%) rotate( -67deg );}
  50%  {transform: translate(0%, 3%) rotate( -65deg );}
  100% {transform: translate(0%, 0%) rotate( -67deg );}
}
@keyframes card-shape1-2 {
  0%   {transform: translate(0%, 0%) rotate( 15deg );}
  50%  {transform: translate(0%, -3%) rotate( 17deg );}
  100% {transform: translate(0%, 0%) rotate( 15deg );}
}
@keyframes card-shape1-3 {
  0%   {transform: translate(0%, 0%) rotate( 35deg );}
  50%  {transform: translate(0%, 7%) rotate( 34deg );}
  100% {transform: translate(0%, 0%) rotate( 35deg );}
}
@keyframes card-shape2-1 {
  0%   {transform: translate(0%, 0%) rotate( -30deg );}
  50%  {transform: translate(0%, 4%) rotate( -32deg );}
  100% {transform: translate(0%, 0%) rotate( -30deg );}
}
@keyframes card-shape2-2 {
  0%   {transform: translate(0%, 0%) rotate( 50deg );}
  50%  {transform: translate(0%, -3%) rotate( 50deg );}
  100% {transform: translate(0%, 0%) rotate( 50deg );}
}
@keyframes card-shape2-3 {
  0%   {transform: translate(0%, 0%) rotate( 11deg );}
  50%  {transform: translate(0%, 4%) rotate( 11deg );}
  100% {transform: translate(0%, 0%) rotate( 11deg );}
}

.no-jobs {
  position: relative;
  width: 358em;
  height: 253em;
  border: 2px dashed #EBE3D7;
  border-radius: $em16;
  margin-top: 24em;
  &__cont {
    text-align: center;
    position: absolute;
    width: 308em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .s--kr & {
      width: 280em;
    }
  }
  &__face {
    position: relative;
    width: 40em;
    height: 40em;
    border-radius: 50%;
    margin: 0 auto;
  }
  &__txt {
    margin-top: 8.5em;
    text-align: center;
  }
  &__desc {
    opacity: 0.4;
  }
}
.channel {
  position: relative;
  width: 212em;
  margin-top: $em16;
  .overlay-1 {
    background: linear-gradient(180deg, rgba(23, 16, 16, 0), rgba(23, 16, 16, 0.4));
    opacity: 1;
  }
  .overlay-2 {
    background: linear-gradient(180deg, rgba(23, 16, 16, 0), rgba(23, 16, 16, 0.2));
  }
  &:hover {
    .overlay-2 {
      opacity: 1;
      transition: opacity 0.25s ease;
    }
  }
  &__map {}
  &__bg {
    border-radius: 16em;
  }
  &__tit {
    position: absolute;
    width: 100%;
    left: 50%;
    // top: 50%;
    bottom: 18em;
    transform: translate(-50%, 0%);
    text-align: center;
    color: $cb-white;
  }
  &__img {
    width: 72em;
    border-radius: 50%;
    overflow: hidden;
    &-wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 0%);
    }
    &-inr {
      position: relative;
    }
  }
  &__state {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    padding: 1em 4em 0em;
    border-radius: 4em;
    &--open {
      background: $cb-green;
    }
    &--close {
      background: $cb-black;
      color: $cb-white;
    }
    &--coming {
      width: 90em;
      background: $cb-yellow;
    }
  }
  &__border {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80em;
    height: 80em;
    border-radius: 50%;
    border: 2px solid $cb-green;
  }
}
.project {
  width: 175em;
  margin-top: $em8;
  &__bg {
    border-radius: 16em;
  }
  &__brand {
    margin-top: $em4;
  }
  &__client {
    opacity: 0.4;
  }
  &__btn {
    position: absolute;
    display: inline-block;
    right: $em8;
    bottom: $em8;
    background-color: rgba(23, 16, 16, 0.1);
    &-wrap {
      position: relative;
      display: inline-block;
    }
  }
  .ico-chatbot--navi-arrow--se {
    font-size: 14em;
    &::before {
      color: $cb-white;
      opacity: 0.8;
    }
  }
}
.news {
  $self: &;
  width: 358em;
  max-width: 100%;
  margin-top: 46em;
  &:first-child {
    margin-top: 0;
  }
  &__person {
    margin-top: 0;
    & > .person {
      margin-top: 0;
    }
  }
  &__txt {
    margin-top: $em8;
  }
  &__img {
    &-wrap {
      position: relative;
      margin-top: $em16;
      border-radius: 2em 16em 16em 16em;
      overflow: hidden;
      .w600__ & {
        &:hover {
          #{$self}__arrow {
            opacity: 1;
            transition: opacity 0.2s ease;
          }
          #{$self}__sound {
            opacity: 1;
            transition: opacity 0.2s ease;
          }
        }
      }
    }
  }
  &__arrow {
    position: absolute;
    top: 50%;
    left: 8em;
    width: 28em;
    height: 28em;
    border-radius: 50%;
    transform: translateY(-50%);
    backdrop-filter: blur(20px);
    opacity: 0;
    &-inr {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .ico-chatbot--navi-navigate--next {
      &::before {
        font-size: 16.8em;
        color: $cb-white;
      }
    } 
    .ico-chatbot--navi-navigate--back {
      &::before {
        font-size: 16.8em;
        color: $cb-white;
      }
    }
    &--next {
      left: auto;
      right: 8em;
    }
  }
  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__sound {
    position: absolute;
    right: $em8;
    bottom: $em8;
    width: 28em;
    height: 28em;
    backdrop-filter: blur(20px);
    border-radius: 50%;
    .w600__ & {
      opacity: 0;
    }
    .b-sound {
      display: inline-block;
      // opacity: 0;
    }
    .ico-chatbot--media-volume--mute {
      &::before {
        font-size: 16.8em;
        color: $cb-white;
      }
    }
    .ico-chatbot--media-volume--up {
      &::before {
        font-size: 16.8em;
      }
    }
  }
  &__more {
    opacity: 0.6;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10em;
      padding-bottom: 10em;
      border-bottom: 1px solid rgba(23, 16, 16, 0.1)
    }
  }
  &__more-arrow {
    opacity: 0.6;
    font-size: 20em;
  }
  &__infinite {
    margin-top: 13em;
  }
}

.infinite {
  $self: &;
  display: flex;
  align-items: center;
  &__btn {
    position: relative;
  }
  &__particles {
    position: absolute;
    opacity: 0;
    height: auto;
    &-wrap {
      &-show {
        &--1 {
          #{$self}__particles {
            animation: infinite-1 0.6s ease;
          }
        }
        &--2 {
          #{$self}__particles {
            animation: infinite-2 0.6s ease;
          }
        }
        &--3 {
          #{$self}__particles {
            animation: infinite-3 0.6s ease;
          }
        }
      }
      &--1 {
        #{$self}__particles {
          &--1 {
            font-size: 18em;
            left: 10%;
            top: -25%;
          }
          &--2 {
            font-size: 15em;
            left: 0%;
            top: 0%;
            animation-delay: 0.07s;
          }
          &--3 {
            font-size: 16em;
            left: 25%;
            top: 0%;
            animation-delay: 0.14s;
          }
          &--4 {
            font-size: 14em;
            left: 10%;
            top: 0%;
            animation-delay: 0.22s;
          }
        }
      }
      &--2 {
        #{$self}__particles {
          &--1 {
            font-size: 16em;
            left: 35%;
            top: -25%;
            animation-delay: 0.1s;
          } 
          &--2 {
            font-size: 16em;
            left: 15%;
            top: -25%;
            animation-delay: 0.18s;
          } 
          &--3 {
            font-size: 14em;
            left: 0%;
            top: -25%;
            animation-delay: 0.24s;
          } 
        }
      }
      &--3 {
        #{$self}__particles {
          &--1 {
            font-size: 16em;
            left: -5%;
            top: 10%;
            animation-delay: 0.2s;
          } 
          &--2 {
            font-size: 14em;
            left: 25%;
            top: -25%;
            animation-delay: 0.35s;
          } 
          &--3 {
            font-size: 14em;
            left: 15%;
            top: 0%;
            animation-delay: 0.4s;
          } 
        }
      }
      .ico-chatbot--content-favorite::before {
        color: $cb-red;
      }
    }
  }
  &__heart {
    position: relative;
    font-size: 24em;
    &-wrap {
      &.clicked {
        #{$self}__heart {
          &--empty {
            display: none;
          }
          &--full {
            display: block;
          }
        }
      }
    }
    &--empty {
      display: block;
    }
    &--full {
      &.ico-chatbot--content-favorite::before {
        color: $cb-red;
      }
      display: none;
    }
  }
  &__counter {
    margin-left: 6em;
  }
  &--video {
    flex-direction: column;
    height: 60em;
    #{$self} {
      &__btn {
        position: relative;
        width: 40em;
        height: 40em;
        border-radius: 50%;
        background: rgba(23, 16, 16, 0.1);
        backdrop-filter: blur(20px);
      }
      &__particles {
        &-wrap {
          &-show {
            &--1 {
              #{$self}__particles {
                animation: infinite-1-video 2s ease;
              }
            }
            &--2 {
              #{$self}__particles {
                animation: infinite-2-video 2s ease;
              }
            }
            &--3 {
              #{$self}__particles {
                animation: infinite-3-video 1s ease;
              }
            }
          }
          &--1 {
            #{$self}__particles {
              &--2 {
                animation-delay: 0.1s;
              }
              &--3 {
                animation-delay: 0.25s;
              }
              &--4 {
                animation-delay: 0.4s;
              }
            }
          }
          &--2 {
            #{$self}__particles {
              &--1 {
                animation-delay: 0.15s;
              } 
              &--2 {
                animation-delay: 0.4s;
              } 
              &--3 {
                animation-delay: 0.55s;
              } 
            }
          }
          &--3 {
            #{$self}__particles {
              &--1 {
                animation-delay: 0.25s;
              } 
              &--2 {
                animation-delay: 0.3s;
              } 
              &--3 {
                animation-delay: 0.5s;
              } 
            }
          }
        }
      }
      &__heart {
        &--empty {
          display: none;
        }
        &--full {
          display: block;
        }
        &-wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .infinite__heart.ico-chatbot--content-favorite::before {
            color: $cb-white;
          }
          &.clicked {
            .ico-chatbot--content-favorite::before {
              color: $cb-red;
            }
          }

        }
      }
      &__counter {
        color: $cb-white;
        margin-left: 0;
        margin-top: 4em;
      }
    }
  }
}

@keyframes infinite-1 {
  0%  {opacity: 0.8; transform: translate(0px, 0px);}
  100% {opacity: 0; transform: translate(0px, -50px);}
}
@keyframes infinite-2 {
  0%  {opacity: 0.8; transform: translate(-5px, 0px);}
  100% {opacity: 0; transform: translate(-5px, -50px);}
}
@keyframes infinite-3 {
  0%  {opacity: 0.8; transform: translate(6px, 0px);}
  100% {opacity: 0; transform: translate(6px, -50px);}
}
@keyframes infinite-1-video {
  0%  {opacity: 0.8; transform: translate(0px, 0px);}
  100% {opacity: 0; transform: translate(0px, -100px);}
}
@keyframes infinite-2-video {
  0%  {opacity: 0.8; transform: translate(-5px, 0px);}
  100% {opacity: 0; transform: translate(-5px, -100px);}
}
@keyframes infinite-3-video {
  0%  {opacity: 0.8; transform: translate(6px, 0px);}
  100% {opacity: 0; transform: translate(6px, -100px);}
}


.topic {
  &__item {
    position: relative;
    width: 48.6%;
    // width: 175em;
    height: 124em;
    margin-top: $em8;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    &:nth-child(1) {
      margin-top: 12em;
      .topic__bg {
        background-color: #EFD6D6;
      }
    }
    &:nth-child(2) {
      margin-top: 12em;
      .topic__bg {
        background-color: #EEE5CB;
      }
    }
    &:nth-child(3) {
      .topic__bg {
        background-color: #D8EECB;
      }
    }
    &:nth-child(4) {
      .topic__bg {
        background-color: #CAECE6;
      }
    }
    &:nth-child(5) {
      .topic__bg {
        background-color: #D7E2EE;
      }
    }
    &:nth-child(6) {
      .topic__bg {
        background-color: #E4DBF0;
      }
    }
    &:nth-child(7) {
      .topic__bg {
        background-color: #F0DBEE;
      }
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &:hover {
      .topic__bg {
        opacity: 0.8;
        transition: opacity 0.25s ease;
      }
      // .b-arrow {
      //   background-color: rgba(23, 16, 16, 0.1);
      //   transition: all 0.25s ease;
      //   &__img {
      //     &--black {
      //       &.ico-chatbot--navi-arrow--se::before {
      //         opacity: 0;
      //       }
      //     }
      //     &--white {
      //       &.ico-chatbot--navi-arrow--se::before {
      //         opacity: 0.8;
      //       }
      //     }
      //   }
      // }
    }
  }
  &__item-tit {
    display: block;
  }
  &__num {
    display: block;
    opacity: 0.6;
  }
  &__bg {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 16em;
  }
  &__emoji {
    position: absolute;
    left: $em16;
    right: $em16;
    top: $em16;
  }
  &__itm-tit {
    &-wrap {
      position: absolute;
      left: $em16;
      right: $em16;
      bottom: $em16;
    }
  }
  &__btn {
    display: inline-block;
    width: 100%;
    height: 100%;
    &-wrap {
      position: absolute;
      right: 13em;
      bottom: 13em;
      width: 16em;
      height: 16em;
    }
  }
}
.explore {
  margin-top: $em64;
  &__banner {
    position: relative;
    margin-top: 12em;
  }
  &__bg {
    border-radius: 16em;
  }
  &__banner-txt {
    position: absolute;
    left: $em16;
    right: $em16;
    top: 11em;
    &--num {
      top: auto;
      bottom: 13em;
    }
  }
  &__link {
    display: inline-block;
    position: relative;
    width: 358em;
    height: 52em;
    max-width: 100%;
    padding: $em16 40em $em16 $em16;
    margin-top: $em8;
    border-radius: 16em;
    background-color: rgba(235, 227, 215, 0.4);
    &:hover {
      background-color: rgba(235, 227, 215, 0.2);
      transition: all 0.25s ease;
      // .b-arrow {
      //   background-color: rgba(23, 16, 16, 0.1);
      //   transition: all 0.25s ease;
      //   &__img {
      //     &--black {
      //       &.ico-chatbot--navi-arrow--se::before, &.ico-chatbot--navi-arrow--ne::before {
      //         opacity: 0;
      //       }
      //     }
      //     &--white {
      //       &.ico-chatbot--navi-arrow--se::before, &.ico-chatbot--navi-arrow--ne::before {
      //         opacity: 0.8;
      //       }
      //     }
      //   }
      // }
    }
  }
  &__btn {
    position: absolute;
    right: 16em;
    top: 50%;
    transform: translateY(-50%);
  }
}

.open-state {
  display: block;
  $self: &;

  &-dfy {
    &--open {
      .open-state-dfy__ball {
        background-color: $cb-green;
      }
    }
    &--close {
      .open-state-dfy__ball {
        background-color: $cb-black;
      }
    }
  }
  &-dflat {
    &--open {
      .open-state-dflat__ball {
        background-color: $cb-green;
      }
    }

    &--close {
      .open-state-dflat__ball {
        background-color: $cb-black;
      }
    }
  }
  &-dflatSeongsu {
    &--open {
      .open-state-dflatSeongsu__ball {
        background-color: $cb-green;
      }
    }

    &--close {
      .open-state-dflatSeongsu__ball {
        background-color: $cb-black;
      }
    }
  }
  &--open {
    #{$self} {
      &__ball {
        background-color: $cb-green;
      }
      // &__open {
      //   display: block;
      // }
      // &__close {
      //   display: none;
      // }
    }
  }
  &--close {
    #{$self} {
      &__ball {
        background-color: $cb-black;
      }
      // &__open {
      //   display: none;
      // }
      // &__close {
      //   display: block;
      // }
    }
  }
}
// page
.c-chatbot {
  $self: &;
  position: relative;
  color: $cb-black;
  cursor: auto;
  // z-index: 1500;
  [data-page-name="404"] & {
    display: none;
  }
  a, button {
    cursor: pointer !important;
  }
  &__btn-box {
    position: fixed;
    right: 16px;
    bottom: 16px;
    // right: 8px;
    // bottom: 8px;
    cursor: pointer;
    width: 54px;
    height: 54px;
    // width: 48px;
    // height: 48px;
    z-index: 1600;
    border-radius: 50%;
    transform: translateY(0);
    transition: transform 0.5s ease, opacity 0.5s ease;
    // box-shadow: 0em 16em 48em rgba(0, 0, 0, 0.15);
    &--hide {
      transform: translateY(calc(100% + 16px));
      opacity: 0;
      transition: transform 0.3s ease, opacity 0.23s ease;
    }
  }
  &.c-chatbot--open .c-chatbot__btn-box {
    pointer-events: none;
    box-shadow: none;
  }
  &__btn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 54px;transition: opacity 0.1s 0.5s ease;
    height: 54px;
    // width: 48px;
    // height: 48px;
    border-radius: 128px 128px 24px 128px;
    background: $cb-white;
    z-index: 1000;
    transform: scale(0);
    transition: transform 0.1s 0.5s ease;
    &-face {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      // border: 0.5px solid rgba(23, 16, 16, 0.1);
      opacity: 1;
      z-index: 1000;
      .__w600 & {
        transition: opacity 0.1s 0.4s ease;
      }
      .w600__ & {
        transition: opacity 0.1s 0.5s ease;
      }
    }
    &-wrap {
      opacity: 1;
      position: absolute;
      right: 0;
      bottom: 0;
      // position: fixed;
      // right: 16px;
      // bottom: 16px;
      
      // right: 8px;
      // bottom: 8px;
      width: 54px;
      height: 54px;
      // width: 48px;
      // height: 48px;
      border-radius: 10px 10px 24px 10px;
      overflow: hidden;
      z-index: 1000;
    }
    .w600__ & {
      transition: width 0.4s 0.1s ease, height 0.4s 0.1s ease, border-radius 0.4s 0.1s ease, transform 0.25s 0.5s ease;
      &-wrap {
        transition: width 0.4s 0.1s ease, height 0.4s 0.1s ease, border-radius 0.4s 0.1s ease;
      }
    }
  }
  &__shadow {
    position: absolute;
    width: 70px;
    height: 70px;
    right: -10px;
    bottom: -24px;
    opacity: 1;
    transition: opacity 0.1s 0.5s;
  }
  &__cont {
    font-size: 1px;
    position: fixed;
    background-color: $cb-white;
    z-index: 1500;
    .__w600 & {
      font-size: 0.25641vw;
      width: 100%;
      height: 0;
      min-height: auto;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 24em 24em 0em 0em;
      border: none;
      box-shadow: none;
      transition: height 0.3s ease;
    }
    .w600__ & {
      right: var(--outerGutter);
      bottom: var(--outerGutter);
      width: 54px;
      height: 54px;
      // width: 48em;
      // height: 48em;
      min-height: 48em;
      border-radius: 50%;
      overflow: hidden;
      opacity: 0;
      // border: 0.5px solid rgba(23, 16, 16, 0.1);
      // box-shadow: 0em 16em 48em rgba(0, 0, 0, 0.15);
    }
  }
  // &__bar {
  //   .__w600 & {
  //     position: absolute;
  //     width: 45.76em;
  //     height: 4em;
  //     top: -10em;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     border-radius: 10em;
  //     background: #F9F5EF;
  //     opacity: 0;
  //     z-index: 1100;
  //   }
  // }
  &__bg {
    display: none;
    .__w600 & {
      position: absolute;
      display: block;
      top: 0;
      width: 100%;
      height: 0;
      transform: translateY(-77%);
      opacity: 0;
      background: $cb-black;
      cursor: pointer;

      overflow-y: scroll;
      > div {
        width: 1px;
        height: 200vh;
        font-size: 16em;
        text-indent: -9999px;
      }
    }
  }
  &__drag-point {
    position: absolute;
    left: 76px;
    top: 0;
    width: calc(100% - 76px);
    height: 100%;
    .__w600 & {
      display: none;
    }
  }
  &--open {
    position: relative;
    #{$self} {
      &__btn {
        transform: scale(1);
        &-face {
          opacity: 0;
          transition: none;
        }
        .__w600 & {
          display: none;
        }
        .w600__ & {
          width: 390px;
          height: 707px;
          border-radius: 10px;
          background: $cb-white;
          transition: width 0.3s ease, height 0.3s ease, border-radius 0.3s ease;
          &-wrap {
            width: 390px;
            height: 707px;
            border-radius: 10px;
            z-index: 50;
            opacity: 0;
            transition: opacity 0.1s 0.6s ease, width 0.3s ease, height 0.3s ease, border-radius 0.3s ease;
          }
        }
      }
      &__shadow {
        opacity: 0;
        transition: none;
      }
      &__cont {
        opacity: 1;
        .__w600 & {
          height: 92%;
          transition: height 0.3s ease;
          transform: none !important; // channel drag -> 쳇봇 전체 드래그 모바일 방지
        }
        .w600__ & {
          width: 390em;
          height: calc(100% - var(--outerGutter)*2);
          max-height: 707em;
          // height: 707em;
          // min-height: 707em;
          border-radius: 10em;
          transition: opacity 0.3s 0.4s ease;
          border: 0.5px solid rgba(23, 16, 16, 0.1);
          box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.15);
        }
      }
      &__bar {
        .__w600 & {
          opacity: 0.6;
        }
      }
      &__bg {
        .__w600 & {
          height: 15%;
          opacity: 0.4;
          transition: opacity 0.3s ease 0.25s;
        }
      }
    }
  }
  .s--in-admin & {
    display: none !important;
  }
}

// page -main
.main {
  position: relative;
  min-height: 100%;
  padding-left: $em16;
  padding-right: $em16;
  padding-top: 26em;
  padding-bottom: 96em;
  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  &__btn {
    position: absolute;
    width: 40em;
    height: 40em;
    top: -10em;
    right: 0em;
    background: rgba(249, 245, 239, 0.4);
    // border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 50%;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    &:hover {
      background: rgba(23, 16, 16, 0.05);
      transition: background 0.25s ease;
    }
  }
}
.main-cont {
  width: 100%;
  height: calc(100% - 28em);
  // height: 679em;
  position: relative;
  .__w600 & {
    height: 100%;
    background-color: #F9F5EF;
  }
}
.main-head {
  $self: &;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  height: 0em;
  background-color: transparent;
  &__inr {
    position: relative;
    width: 100%;
    height: 0;
    background-color: rgba(249, 245, 239, 0.8);
    backdrop-filter: blur(20px);
    transform: translateY(-100%);
    opacity: 0;
  }
  &__tit {
    position: absolute;
    align-items: center;
    white-space: nowrap;
    left: 16em;
    top: 50%;
    transform: translate(0%, -50%);
  }
  &__btn {
    width: 32em;
    height: 32em;
    transition: all $head-duration ease;
    background-color: transparent;
    color: $cb-black !important;
    position: absolute;
    right: 16em;
    top: 50%;
    transform: translate(0%, -50%);
    &:hover {
      background: rgba(23, 16, 16, 0.05);
    }
  }
  &--scroll {
    #{$self}__inr {
      height: 48em;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.5s ease;
    }
  }
}
.main-tit {
  position: relative;
  &__tit {
    width: 100%;
    height: 32em;
    overflow: hidden;
    &-wrap {
      display: flex;
      margin-top: 18em;
    }
  }
  &__inr {
    position: relative;
  }
  &__txt {
    display: flex;
    white-space: nowrap;
  }
  &__hand {
    width: 30em;
    margin-left: 4em;
    transform-origin: 60% 67%;
    animation: wave-hand 2s 1s infinite both;
  }
  &--soft {
    opacity: 0.6;
  }
}
@keyframes wave-hand {
  0%   {transform: rotate(0deg);}
  6%   {transform: rotate(20deg);}
  12%  {transform: rotate(0deg);}
  18%  {transform: rotate(20deg);}
  24%  {transform: rotate(0deg);}
  100% {transform: rotate(0deg);}
}
@keyframes main-tit {
  0%   {transform: translateY(100%); opacity: 0;}
  100% {transform: translateY(0%); opacity: 1;}
}
.main-subtit {
  width: 100%;
  height: 32em;
  overflow: hidden;
  .s--kr & {
    height: 35em;
  }
  
  &__inr {
    position: relative;
  }
  &__item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    animation: change-subtit 16s ease infinite;
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 4s;
    }
    &:nth-child(3) {
      animation-delay: 8s;
    }
    &:nth-child(4) {
      animation-delay: 12s;
    }
  }
}
@keyframes change-subtit {
  0%   {transform: translateY(100%); opacity: 0;}
  2%   {transform: translateY(0%); opacity: 1;}
  25%  {transform: translateY(0%); opacity: 1;}
  27%  {transform: translateY(-100%); opacity: 0;}
  100% {transform: translateY(-100%); opacity: 0;}
}
.main-card {
  margin-top: 94em;
}
.main-job {
  margin-top: $em64;
  &__card {
    margin-top: $em16;
  }
}
.main-channel {
  margin-top: $em64;
  &__card {
    margin-right: 4em;
    margin-left: 4em;
    &-wrap {
      margin-right: -4em;
      margin-left: -4em;
      white-space: nowrap;
    }
  }
}
.main-news {
  margin-top: $em64;
}
.main-project {
  margin-top: 80em;
  &__label-arrow {
    font-size: 14em;
  }
}
.main-topic {
  margin-top: 80em;
}
.main-explore {
  margin-top: $em64;
}
.power {
  width: 100%;
  margin-top: 96em;
  text-align: center;
  &__txt {
    opacity: 0.4;
  }
}

// page -subpage 
.subpage {
  width: 100%;
  height: 100%;
  background-color: $cb-white;
  overflow: hidden;
  overflow-y: auto;
  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.4s ease;
    z-index: 250;
    .__w600 & {
      height: calc(100% - 40em);
    }
    &--open {
      transform: translateX(0);
      transition: transform 0.4s ease;
    }
  }
  .__w600 & {
    height: calc(100% + 1px);
  }
}
.p-hiring {
  padding-bottom: $em32;
  padding-left: $em16;
  padding-right: $em16;
  margin-left: -$em16;
  margin-right: -$em16;
  margin-bottom: $em32;
  border-bottom: 1px solid rgba(23, 16, 16, 0.1);
  &__info {
    &:nth-child(1) {
      width: 212em;
    }
    &:nth-child(2) {
      margin-left: 8em;
    }
    &-wrap {
      display: flex;
      // justify-content: space-between;
    }
  }
  &__info-tit {
    opacity: 0.4;
  }
  &__info-txt {
    margin-top: $em4;
  }
  &__desc {
    margin-top: $em32;
  }
  &__required {
    display: flex;
    margin-top: 21em;
    opacity: 0.6;
    &-wrap {
      margin-top: $em32;
    }
  }
  &__req-num {
    margin-right: 59em;
  }
  .t--fak {
    word-break: keep-all;
  }
}
.p-channel {
  &__tit {
    opacity: 0.4;
  }
  &__info {
    margin-top: $em4;
    &--kr {
      word-break: keep-all;
    }
    &-line {
      margin-top: 16em;
    }
  }
  &__location {
    margin-top: $em16;
    opacity: 0.6;
  }
  &__btn {
    display: flex;
    align-items: center;
    margin-left: 4em;
    margin-right: 4em;
    &-wrap {
      display: flex;
      margin-left: -4em;
      margin-right: -4em;
      margin-top: $em16;
      opacity: 0.8;
    }
  }
  &__cont {
    padding-top: $em32;
    padding-left: $em16;
    padding-right: $em16;
    margin-top: $em32;
    border-top: 1px solid rgba(23, 16, 16, 0.1);
    margin-left: -16em;
    margin-right: -16em;
  }
  & + .power {
    margin-bottom: 73em;
  }
}
.sub-policy {
  position: relative;
  padding-left: $em16;
  padding-right: $em16;
  padding-top: $em32;
  padding-bottom: $em64;
  background-color: $cb-white;
  &-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 81.1487482%;
    border-radius: 24em 24em 0em 0em;
    margin-top: 128em;
    overflow: hidden;
    transform: translateY(100%);
    transition: transform 0.4s ease;
    z-index: 400;
    &--open {
      transform: translateY(0);
      transition: transform 0.4s ease;
      + .sub-policy-bg {
        height: 679em;
        opacity: 0.4;
        transition: opacity 0.3s ease;
        cursor: pointer;
      }
    }
    &--scroll {
      overflow-y: auto;
    }
  }
  &-bg {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0em;
    background: $cb-black;
    opacity: 0;
    z-index: 350;
  }
  &__close {
    position: absolute;
    width: 100%;
    height: 40em;
    top: 0em;
    left: 0;
  }
  &__bar {
    position: absolute;
    left: 50%;
    top: 10em;
    width: 45.76em;
    height: 4em;
    transform: translateX(-50%);
    background: rgba(23, 16, 16, 0.1);
    border-radius: 10em;
  }
  &__link {
    color: $cb-coffee;
  }
  &__cont {
    &-wrap {
      margin-top: $em32;
      .t--kr {
        font-size: 13.6em;
        line-height: 1.439;
      }
    }
  }
  &__date {
    margin-top: $em16;
    .t--kr {
      font-size: 13.6em;
      line-height: 1.439;
    }
  }
  &__privacy {
    position: absolute;
    // width: 21.33em;
    top: 33em;
    right: 15em;
    .ico-chatbot--content-lock::before {
      font-size: 32em;
      color: $cb-blue;
    }
  }
  .__w600 {
    margin-top: 0;
  }
}
.sub-subpage {
  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform 0.4s ease;
    z-index: 500;
    &--open {
      transform: translateX(0%);
      transition: transform 0.4s ease;
    }
    &--scroll {
      overflow-y: auto;
    }
    .__w600 & {
      height: calc(100% - 40em);
    }
  }
}
.sub-video {
  height: 100%;
  &__bg {
    width: auto;
    height: 100%;
  }
  &__head {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 72em;
    padding-left: 16em;
    padding-right: 16em;
  }
  &__location {
    color: $cb-white;
  }
  &__info {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 52em;
    left: 50%;
    transform: translateX(-50%);
    color: $cb-white;
  }
  &__state {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 37em;
    height: 16em;
    background-color: rgba(23, 16, 16, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 4em;
    width: 37em;
    height: 16em;
    &-light {
      display: inline-block;
      width: 6em;
      height: 6em;
      border-radius: 50%;
      margin-right: 2em;
      background-color: transparent;
      &--red {
        background-color: $cb-red;
      }
    }
    &-txt {
      display: inline-block;
    }
  }
  &__time {
    display: inline-block;
    min-width: 46em;
    margin-left: 8em;
  }
  &__face {
    width: 40em;
    height: 40em;
    border-radius: 50%;
  }
  &__heart {
    position: absolute;
    right: 16em;
    bottom: 16em;
  }
}
.sub-map {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $cb-beige;
  &__btn {
    position: absolute;
    top: 16em;
    left: 16em;
  }
}
.p-btn {
  position: sticky;
  z-index: 30;
  right: 0em;
  top: 0;
  width: 100%;
  &__back {
    position: absolute;
    top: $em16;
    left: $em16;
  }
  &__lang {
    position: absolute;
    top: $em16;
    right: $em16;
    width: 40em;
    height: 40em;
    background-color: rgba(249, 245, 239, 0.4);
    &:hover {
      background-color: rgba(249, 245, 239, 0.8);
    }
  }
}
.p-layout {
  $self: &;
  position: relative;
  &__fill {
    position: absolute;
    width: 100%;
    padding-top: 100%;
    top: 0;
    left: 0;
  }
  &__inr {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: auto;
    background: $cb-white;
  }
  &__main {
    position: relative;
    &:hover {
      #{$self}__arrow {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }
  }
  &__arrow {
    position: absolute;
    top: 50%;
    left: 16em;
    width: 28em;
    height: 28em;
    border-radius: 50%;
    transform: translateY(-50%);
    backdrop-filter: blur(20px);
    opacity: 0;
    z-index: 6;
    &-inr {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .ico-chatbot--navi-navigate--next {
      &::before {
        font-size: 16.8em;
        color: $cb-white;
      }
    } 
    .ico-chatbot--navi-navigate--back {
      &::before {
        font-size: 16.8em;
        color: $cb-white;
      }
    }
    &--next {
      left: auto;
      right: 16em;
    }
  }
  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__dim {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 194em;
    z-index: 7;
    background: linear-gradient(180deg, rgba(23, 16, 16, 0) 0%, rgba(23, 16, 16, 0.4) 100%);
    &--hiring {
      height: 150em;
    }
  }
  &__bg {
    position: relative;
    z-index: 5;
    &-wrap {
      position: relative;
      z-index: 5;
      .card__shape-wrap {
        z-index: 6;
      }
    }
  }
  &__shape {
    position: absolute;
    width: 91.7948718%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__txt {
    &-wrap {
      position: absolute;
      left: $em16;
      right: $em16;
      bottom: 48em;
      z-index: 100;
      transform: translate3d(0,0,0);
    }
  }
  &__tit {
    margin-top: $em4;
  }
  &__location {
    opacity: 0.4;
  }
  &__desc {
    margin-top: $em8;
  }
  &__info {
    margin-top: $em8;
    color: $cb-white;
    &-wrap {
      position: absolute;
      left: 16em;
      right: 16em;
      bottom: 48em;
      text-align: left;
      z-index: 8;
    }
  }
  &__face {
    width: 72em;
    height: 72em;
    border-radius: 50%;
  }
  &__open-time {
    margin-top: 2em;
    text-align-last: left;
    color: $cb-white;
  }
  &__state {
    display: inline-block;
    padding: 1em 4em 0em;
    border-radius: 4em;
    margin-right: $em4;
    white-space: nowrap;
    &--open {
      background: $cb-green;
    }
    &--close {
      background: $cb-black;
      color: $cb-white;
    }
    &--coming {
      width: 90em;
      background: $cb-yellow;
    }
  }
  &__cont {
    position: relative;
    width: 100%;
    background-color: $cb-white;
    margin-top: -24px;
    border-radius: 24em 24em 0em 0em;
    padding-left: 16em;
    padding-right: 16em;
    padding-top: $em32;
    padding-bottom: 40em;
    z-index: 10;
  }
  &__date {
    text-align: center;
    &-wrap {
      opacity: 0.4;
    }
  }
  &__time {
    text-align: center;
  }
}

.chat-left {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}
.chat-right {
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}
.chat-comp {
  opacity: 1;
  transform: translateY(0em);
  transition: all 0.4s ease;
  &--uncomplete {
    opacity: 0;
    transform: translateY(20em);
  }
}
.chat-loading {
  opacity: 0;
  transform: translateY(15em);
  &--complete {
    opacity: 1;
    transform: translateY(0em);
    transition: all 0.4s ease;
  }
}
.chat-link {
  color: $cb-coffee;
  text-decoration: underline;
}
.SelectText {
  &__info {
    margin-top: 16em;
    color: #D2330F;
  }
  &__team {
    position: relative;
    padding-left: 10em;
    margin-top: 8em;
    margin-left: 8em;
    &::before {
      content: '•';
      position: absolute;
      top: 50%;
      left: -0.5em;
      transform: translateY(-50%);
    }
  }
  &__item {
    margin-left: $em4;
    margin-right: $em4;
    &-wrap {
      margin-left: -$em4;
      margin-right: -$em4;
    }
  }
  &__btn {
    display: none;
    // display: block;
    &--show {
      display: block;
    }
  }
  &__confirm {
    opacity: 1;
    &--hide {
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }
}
.TextInput {}

// 모바일 쳇봇 상단 스크롤 block
.chat-body-lock {
  overflow: hidden !important;
  // pointer-events: none;
  height: 100vh;
}

.scroll-lock {
  pointer-events: none;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
  }
}


