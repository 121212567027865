// module margin(em)
$em4: 4em;
$em8: 8em;
$em16: 16em;
$em32: 32em;
$em64: 64em;
$em128: 128em;
$em256: 256em;

// module margin(px)
$px4: 4px;
$px8: 8px;
$px16: 16px;
$px32: 32px;
$px64: 64px;
$px128: 128px;
$px256: 256px;

/* --------------------------------------------------
 * Grid system
 * -------------------------------------------------- */
// 1024(base)
$baseWidth: 1024em;
$cols: 15;
$gutter: 16em;
$outerGutter: 16em;
$rowWidth: $baseWidth - ($outerGutter * 2) + $gutter; // 1008
$col: ($rowWidth / $cols) - $gutter; // 51.2

// 600
$sbaseWidth: 390em;
$scols: 5;
$sgutter: 8em;
$souterGutter: 16em;
$srowWidth: $sbaseWidth - ($souterGutter * 2) + $sgutter; // 366
$scol: ($srowWidth / $scols) - $sgutter; // 65.2

// 1600
$lbaseWidth: 1600em;
$lcols: 15;
$lgutter: 16em;
$louterGutter: 16em;
$lrowWidth: $lbaseWidth - ($louterGutter * 2) + $lgutter;
$lcol: ($lrowWidth / $lcols) - $lgutter;

// column width(w1024__)
$cw1: 1 * ($col + $gutter) - $gutter; // 51.2
$cw2: 2 * ($col + $gutter) - $gutter; // 118.4
$cw3: 3 * ($col + $gutter) - $gutter; // 185.6
$cw4: 4 * ($col + $gutter) - $gutter; // 252.8
$cw5: 5 * ($col + $gutter) - $gutter; // 320
$cw6: 6 * ($col + $gutter) - $gutter; // 387.2
$cw7: 7 * ($col + $gutter) - $gutter; // 454.4
$cw8: 8 * ($col + $gutter) - $gutter; // 521.6
$cw9: 9 * ($col + $gutter) - $gutter; // 588.8
$cw11: 11 * ($col + $gutter) - $gutter; // 723.2
$cw12: 12 * ($col + $gutter) - $gutter; // 790.4
$cw13: 13 * ($col + $gutter) - $gutter; // 857.6

// column width(__w600)
$cw1s: 1 * ($scol + $sgutter) - $sgutter; // 65.2em
$cw2s: 2 * ($scol + $sgutter) - $sgutter; // 138.4em
$cw3s: 3 * ($scol + $sgutter) - $sgutter; // 211.6em
$cw4s: 4 * ($scol + $sgutter) - $sgutter; // 284.8em

//color
//foundation
$f-black: #171010; //onyx
$f-white: #F9F5EF; //spring wood
$f-beige: #EBE3D7; //satin linen
$f-red: #D2330F; //sinopia
//service
$s-green: #009E60; //shamrock green
$s-brown: #A6814C; //coffee
$s-yellow: #fbb116; //beer