
.loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1200;
	&__minime {
		position: fixed;
		left: 50%;
		top: 65vh;
		transform: translate(-50%, -50%);
		z-index: 1210;
		opacity: 0;
		@media screen and (max-width: 599px) {
			top: 55vh;
		}
		.s--show-curtain & {
			transition: opacity 0.5s 0s ease;
			opacity: 1;
		}
		.s--curtain-fade & {
			transition: opacity 0.25s ease;
			opacity: 0;
		}
	}
	&__layer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		&--front {
			z-index: 50;
			.loader__title {
				opacity: 0;
				transform: translate3d(0, 25em, 0);
			}
			.loader__title--show {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition: transform 0.5s 0.35s ease, opacity 0.5s 0.35s ease;
			}
		}
		&--bg {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 40;
		}
		&--mobile-header {
			z-index: 30;
		}
		&--back {
			transform: translate3d(0, 0, 0) !important;
			z-index: 20;
			.loader__title {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
	}
	&__bg-slider {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		// height: var(--vh);
		background-color: #171010;
		transform: translateY(100%);
		visibility: hidden;
		.s--show-curtain & {
			transform: translateY(0);
			visibility: visible;
			transition: transform 0.6s cubic-bezier(0.4, 0, 0,1);
		}
		.s--curtain-fade & {
			opacity: 0;
			transition: opacity 0.5s;
		}
	}
	.s--no-smooth-scroll & {
		position: relative;
		.loader__layer--front {
			position: fixed;
		}
		.loader__layer--bg {
			position: fixed;
		}
		.loader__layer--mobile-header {
			position: fixed;
		}
	}
	.__w600 & {
		z-index: 1200;
	}
}

.splash {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #171010;
	color: #EBE3D7;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	transition: clip-path 0.5s cubic-bezier(0.4, 0, 0,1);
	pointer-events: none;
	overflow: hidden;
	z-index: 99999;
	font-size: 0.09765vw;
	@media screen and (min-width: 1600px) {
		font-size: 0.0625vw;
	}
	@media screen and (max-width: 599px) {
		font-size: 0.25641vw;
	}
	.s--no-smooth-scroll & {
		position: fixed;
		height: 100%;
	}
	.c-gear__svg path { fill: #EBE3D7; }
	&__title {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		// font-size: 200em;
		transform: translate3d(0, -50%, 0);
		opacity: 0;
		transition: opacity 0.45s 0s cubic-bezier(0.4, 0, 0,1);
		.mqt {
			margin-top: 0;
		}
	}
	// &__title-item {
	// 	// padding-left: var(--outerGutter);
	// }
	&__title-txt {
		position: relative;
		font-size: 128em !important;
		@media screen and (min-width: 1600px) {
			font-size: 200em !important;
		}
		@media screen and (max-width: 599px) {
			font-size: 54em !important;
		}
	}
	&__title-txt-dfy {
		margin-right: -0.09em;
	}
	&__tm {
		position: absolute;
    left: 100%;
    transform: scale(0.075) translateX(-39.3%);
    display: inline-block;
    transform-origin: left bottom;
    bottom: 22.5%;
	}
	&__bot {
		position: absolute;
		left: var(--gutter);
		right: var(--gutter);
		bottom: var(--gutter);
		opacity: 0;
		transition: opacity 0.45s 0s cubic-bezier(0.4, 0, 0,1);
		white-space: nowrap;
		@media screen and (max-width: 599px) {
			left: 16em;
			right: 16em;
			bottom: 16em;
		}
	}
	&__bar {
		display: inline-block;
		width: 0;
		height: 1px;
		margin-right: 4em;
		background-color: #EBE3D7;
	}
	&__year {
		display: inline-block;
	}
	&__year-txt {
		font-size: 54em;
		&:before {
			content: "©";
		}
		@media screen and (max-width: 599px) {
			font-size: 28em !important;
		}
	}
	@at-root .s--splash-start {
		.splash__title {
			opacity: 1;
		}
		.splash__bot {
			opacity: 1;
		}
	}
	@at-root .s--splash-end {
		.splash {
			clip-path: polygon(0 0, 100% 0%, 100% 0%, 0 0);
		}
	}
}

// 추가 이유: mysdcstack 390 이하에서 타이틀 넘침
[data-page-name="project"] .loader__layer--back .loader__title {
	overflow: hidden;
}

