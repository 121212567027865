
.mheader {
	$firstDelay: 0.28s;
	$stagger: 0.13s;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	&__layer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		&--1 {
			z-index: 10;
		}
		&--2 {
			display: none;
			width: 100%;
			height: 100vh;
			background-color: #171010;
			transform: translate3d(0, 100%, 0);
			z-index: 20;
		}
		&--3 {
			z-index: 30;
		}
	}
	&__logo {
		position: absolute;
		display: inline-block;
		top: 16px;
		left: var(--outerGutter);
		path {
			fill: var(--foreground);
			transition: fill 0.3s;
		}
	}
	&__nav {
		position: absolute;
		display: none;
		left: var(--outerGutter);
		top: 50vh;
		transform: translateY(-50%);
		color: #EBE3D7;
	}
	&__nav-item {
		margin-top: 8em;
		opacity: 0;
		transform: translate3d(0, 30px, 0);
		&:first-child {
			margin-top: 0;
		}
		&:nth-child(1) {
			transition: transform 1s $firstDelay + $stagger * 0 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 0 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(2) {
			transition: transform 1s $firstDelay + $stagger * 1 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 1 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(3) {
			transition: transform 1s $firstDelay + $stagger * 2 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 2 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(4) {
			transition: transform 1s $firstDelay + $stagger * 3 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 3 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(5) {
			transition: transform 1s $firstDelay + $stagger * 3 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 4 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
	}
	&__nav-lnk .t--rec {
		font-size: 54em;
    font-weight: 400;
    letter-spacing: 0;
		text-transform: lowercase;
	}
	&__bot {
		position: absolute;
		display: none;
		left: var(--outerGutter);
		top: 100vh;
    transform: translateY(calc(-100% - var(--outerGutter)));
		color: #EBE3D7;
		opacity: 0;
		.t--fak {
			font-size: 11em;
		}
	}
	&__right {
		position: absolute;
		right: var(--outerGutter);
		top: 16px;
		margin-top: -2px;
	}
	&__btn {
		display: inline-block;
		padding: 3px 5px 2px 5px;
		border-radius: 4px;
		font-family: "Faktum";
		text-transform: uppercase;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: -0.04em;
		line-height: 1;
		vertical-align: middle;
		transition: color 0.3s, background-color 0.3s;
		color: var(--background);
		background-color: var(--foreground);
	}
	&--open {
		.mheader__nav {
			display: block;
		}
		.mheader__bot {
			display: block;
		}
		.mheader__btn {
			color: #171010;
			background-color: #EBE3D7;
		}
		.mheader__logo path {
			fill: #EBE3D7;
		}
		.mheader__layer--2 {
			display: block;
		}
	}
	&--open-ani {
		.mheader__layer--2 {
			transform: translate3d(0, 0, 0);
			transition: transform 0.6s cubic-bezier(0.4, 0, 0,1), opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
		.mheader__nav-item {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
		.mheader__bot {
			opacity: 1;
			transition: opacity 0.8s $firstDelay + $stagger * 5 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
	}
	&--close-ani {
		.mheader__layer--2 {
			opacity: 0;
			transition: transform 0.6s cubic-bezier(0.4, 0, 0,1), opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
		.mheader__nav {
			opacity: 0;
			transition: opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
		.mheader__bot {
			opacity: 0;
			transition: opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
	}
}
