
$f-black: #171010; //onyx
$f-white: #F9F5EF; //spring wood
.btn-ripple {
  .ripple {
    position:absolute;
    z-index: 0;
    transform: scale(0);
    transition: transform .3s linear;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $f-black;
  }
  &.active {

    .c-button__inner  {
      color: $f-white;
    }
    .ripple {
      transform: scale(100);
    }
  }
  .c-button--black & {
    .ripple{
      background: $f-white;
    }
    &.active {
      .c-button__inner  {
        color: $f-black;
      }
    }
  }
}
.c-button {
  display: inline-block;
  cursor: pointer;
  padding-bottom: 2em;
  &__inner {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border: 1px solid #171010;
    border-radius: 14em;
    padding: 4em 8em;
    transition: 0.3s ease all;
    transform: translateZ(0);
  }
  &__txt {
    white-space: nowrap;
    position: relative;
    z-index: 1;
  }
  
  &--xs {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 14em;
        padding: 3em 6em;
      }

    }
  }
  &--s {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 12em ;
        padding: 2em 8em;
      }
    }
  }
  &--l {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 20em ;
        padding: 3em 13em;
      }
    }
  }
  &--xl {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 28em ;
        padding: 0em 18em;
      }
    }
  }
  &--black {
    color:#EBE3D7;
    .c-button__inner {
      border: 1px solid rgba(235, 227, 215, 0.4);
    }
    .normal-btn {
      &:hover {
        .c-button__inner {
          border: 1px solid rgba(235, 227, 215, 1);
        }
      }
     
    }
    &.c-button--xs {
      .c-button__inner {
        border-radius: 10em;
        padding: 0 4em;
        .t-h--13s .t--fak{
          font-size: 11em;
          letter-spacing: -0.02em;
          line-height: 1.3;
        }
      }
    }
  }
  &--tp {
    .c-button__inner {
      border: 1px solid rgba(23, 16, 16, 0.4);
    }
  }
  &--footer {
    width: 100%;
    .footer-btn {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 238em;
      border-radius: 96em;
      border: 1px solid #EBE3D7;
      transition: 0.3s ease all;
      &:hover {
        border-radius: 30em;
      }
      &__inner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 192em);
        height: 100%;
        

      }
      .__w600 & {
        height: 220em;;
        &__inner {
          // width: calc(100% - 120em);
          // width: calc(100% - 92em);
          width: calc(100% - 60em);

        }
      }
      
    }
  }
  &--active{
    // background: #171010;
    .c-button__inner {
      background: #171010;
      color: #F9F5EF;
    }
  }
}
.c-button--xs,
.c-button--s,
.c-button--m,
.c-button--l {
  .normal-btn {
    &:hover {
      .c-button__inner {
        border-radius: 6em;
      }
    }
  }
}
.c-button--xl {
  .normal-btn {
    &:hover {
      .c-button__inner {
        border-radius: 12em;
      }
    }
  }
}
.__w600 {
  .c-button--l-s {
    .normal-btn {
      &:hover {
        .c-button__inner {
          border-radius: 6em;
        }
      }
    }
  }
}

.c-button--tp {
  .normal-btn {
    &:hover {
      .c-button__inner {
         border: 1px solid rgba(23, 16, 16, 1);
      }
    }
  }
}
.__w600 .footer--work {
  .footer-btn__inner {
    width: calc(100% - 120em);
  }
}

.__w600 .s--mobile-precise .c-button__inner { padding-top: 1em; padding-bottom: 1em; }
.__w600 .s--mobile-precise .t--fak { line-height: 1 !important; }

// .c-button--black.c-button--xs .c-button__inner { padding-top: 1em !important; padding-bottom: 1em !important; }
// .c-button--black.c-button--xs .c-button__inner .t-h--13s .t--fak { line-height: 1 !important; }
