
.mqt {
	position: relative;
	overflow: hidden;
	z-index: 30;
	&__item-wrap {
		position: relative;
		display: flex;
		white-space: nowrap;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		z-index: 30;
	}
	&__item {
		display: inline-flex;
		will-change: transform;
	}
	&--reverse {
		.mqt__item-wrap {
			flex-direction: row-reverse;
		}
	}
	&--play {
		.mqt__item-wrap {
		}
	}
}

// temp
// .mqt {
// 	padding: 20px 0;
// 	&__item {
// 		// border: solid 1px;
// 	}
// }
