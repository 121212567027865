
  .c-last {
    &__kv {
      .__w600 & {
        margin: 0;
      }
      &-img {
        position: relative;
        :deep .m-pic {
          height: 100%;
          &__img {
            height: 110%;
            object-fit: cover;
          }
        }
        .__w600 & {
          position: relative;
          padding-top: 100%
        }
        &-inner {
          .__w600 & {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
      &-pic {
        padding-top: 70.766% !important;
        .__w600 & {
          padding-top: 100% !important;
        }
      }
      &-txt {
        margin-top: 4em;
        position: relative;
        .__w600 & {
          margin-top: 4em;
        }
      }
      .desc-polygon {
        $base:&;
        float: left;
        // margin-right: 16em;
        width: var(--cw6);
        height: 200em;
        &__txt {
          // margin-top: 8em;
          text-transform: uppercase;
        }
        // Type1 스타일
        &--type1 {
          margin-top: 97em;
          width: 400em;
          height: auto;
          shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 100%) content-box;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          .desc-polygon__img {
            width: calc(100% - 12em);
          }
          .__w600 & {
            margin-top: 85em;
            width:  146em;
            &.desc-polygon--mo {
              margin-top: 88em;
            }
            .desc-polygon__img {
              width: calc(100% - 8em);
            }
          }
          .w1600__ & {
            // margin-top: 69em;
            width: 633em;
            .desc-polygon__img {
              width: calc(100% - 16em);
            }
          }
        }

        // Type2 스타일
        &--type2 {
          position: absolute;
          top: 0;
          right: 0;
          width: var(--cw6);
          height: 274em;
          margin-top: -120em;
          margin-right: 0;
          .__w600 & {
            margin-top: 0;
            width: 76em;
            height: auto;
            .desc-polygon__txt {
              display: none;
            }
          }
        }

        &--right {
          float: right;
          margin-right: 0;
        }

        // Type2 하이드 타입 (contact) 스타일
        &--hide {
          display: none;
          .w1600__ & {
            display: block;;
          }
        }
      }
      .kv-txt {
        &__kr {
          display: inline-block;
          width: var(--cw5);
          text-indent: calc(var(--cw1) + var(--gutter));
          margin-left: calc(var(--cw3) + var(--gutter));
          .__w600 & {
            display: block;;
            width: var(--cw3);
            margin-left: calc(var(--cw2) + var(--gutter));
          }
          .w1600__ & {
            width: var(--cw4);
          }
        }
      }
    }
    &__kv-desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(23, 16, 16, 0) 0%, rgba(23, 16, 16, 0.2) 100%);;
      z-index: 30;
      text-align: right;
      padding: 40em 8em 8em 8em;
    }
  }

  .t-dfy {
    color: inherit;
    &:after {
      color: inherit;
    }
  }

