
// .main-intro__yt{
// 	background: rgba(0,0,0,0.8);
// }
.yt-inner{
	// position: absolute;
	// top: 60em;
	// left: 0;
	// bottom: 60em;
	// width: 100%;
	
	width: 100%;
	height: 100%;
}
.yt-player{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
