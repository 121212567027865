
@import "@/css/_settings.scss";
.policy{    
  $self: &;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	color: $f-black;
	position: relative;
	// background: #f9f5ef;
	&__inner {
		opacity: 0;
    -webkit-transition: opacity .35s .3s ease-in;
    transition: opacity .35s .3s ease-in;
	}
	&__inner--center{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
	}
	&__tit{
		margin-top: 96em;
		text-align: center;
		.__w600 &{
			margin-top: 48em;
			text-align: left;
		}
	}
	&__cont{
		overflow-y: scroll;
	}
	&__txt-wrap{
		position: relative;
		&--kr {
			margin-left: calc(var(--cw1) + 2 * var(--gutter));
			.__w600 & {
				margin-left: 0;
				margin-top: 32em;
			}
		}
		.policy__lang-type{
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			font-size: 11em;
			line-height: 2.5em;
			color: #171010;
			.__w600 &{
				font-size: 9em;
			}
		}
		.t-b--12 .t--fak{
			font-size: 12em;
		}
	}
	&__foot{
		margin-top: -52em;
		padding-bottom: 101em;
		position: relative;
		text-align: center;
		.__w600 &{
			margin-top: 0;
			padding-bottom: 74em;
			text-align: left;
		}
	}
	&--open {
		#{$self}__inner {
			opacity: 1;
		}
	}
	&__minime--mo {
		:deep(.g-row) {
			justify-content: flex-end;
		}
	}
}
