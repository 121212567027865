
.credits{
  $self: &;
	height: 100vh;
	overflow: hidden;
  background: #171010;
	color:#EBE3D7;
	text-transform: uppercase;
  .t-dfy{
    color:#EBE3D7;
    &::after{
      color:#EBE3D7;
    }
  }
  &__video{
		// opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(var(--baseWidth));
    height: 100vh;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transition: all .8s .5s ease-in;
    transition: all .8s .5s ease-in;
    video{
			position: absolute;
			left: 0;
			top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      .w1600__ &{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
	&__video-pad {
		position: absolute;
		left: 50%;
		top: 50%;
		padding-top: 75%;
		width: 100%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: opacity 0.8s 0.5s ease-in;
		.__w600 & {
			padding-top: 100%;
		}
	}
  &__cont{
    position: absolute;
    top: 100vh;
    left: 0;
    width: 100%;
    z-index: 3;
		&-inner{
			position: relative;
			z-index: 2;
		}
  }
  &__member{
    margin-top: 32em;
    &:first-of-type{
      margin-top:0;
    }
  }
	&__fonts {
		.__w600 & {
			line-height: 1.35 !important;
		}
	}
	&__guests {
		.__w600 & {
			margin-top: 0;
		}
	}
	&__guests-c-wrap{
		.__w600 &{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin: 16em 0;
			ul{
				flex-basis: auto;
    		width: 50%;
			}
		}
	}
  .happy-svg {
    width: 236em;
    margin: 0 auto;
  }
	.bg-item{
		z-index:1;
		&__target{
			position:absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.t-block{
		text-indent: 0 !important;
	}
}
.credits--open{
	.credits__video{
		width: calc(var(--cw9));
		font-size: 1px;
		.w1600__ &{
			width: calc(var(--cw7));
		}
		.__w600 &{
			font-size: inherit;
			width: calc(var(--cw4));
		}
		// padding-top: 51.4vh;
		img{
			width: 100%;
			height: auto;
		}
	}
	.credits__video-pad {
		opacity: 0.85;
	}
}

// .with-modal-popup .i-canvas{
//   height : 100vh;
// }
// .with-modal-popup .c-noise{
// 	&:before {
//     z-index: 1003;
//   }
// }
// .with-modal-popup .page-title{
// 	z-index: -1;
// }
