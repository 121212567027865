
  .chat-google-map {
    width: 100%;
    height: 700px;
    .__w600 & {
      height: 100vh;
    }
    // height: 100%;
    // min-height: 700px;
    .map-for-api {
      width: 100%;
      height: 100%;
    }
  }
