
// @import "@/css/_settings.scss";
.c-footer {
	position: relative;
	left: 0;
	right: 0;
	bottom: 0;
	color: #EBE3D7;
	overflow: hidden;
	z-index: 30;
	// color: #EBE3D7;
	// &::before {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	left:0;
	// 	width: 100%;
	// 	height: 100%;
	// 	// background-color: $f-black;
	// 	// background: url('~@/assets/work/dfy_wrok_contact-banner-texture.png') no-repeat 0 0;
	// 	// background-size: cover;
	// 	z-index: -1;
	// }

	// Footer Top 스타일
	&__top {
		.c-last {
			&__inner {
				padding: 16em 16em 0;
				.w1600__ & {
					padding: 16em 16em 0;
				}
				&--wide {
					padding: 16em 0;
					.w1600__ & {
						padding: 16em 0;
					}
				}

			}
		}
	}

	// Footer Bottom 스타일
	&__btm {
		margin-top: 71em;
		.__w600 & {
			margin-top: 70em;
		}
		.w1600__ & {
			margin-top: 77em;

		}
	}
}

