
@import "@/css/_settings.scss";
  .footer-object-desc {
    position: relative;
  }
  .c-last {
    &__desc {
      &-item {
        text-indent: 3.8em;
        .__w600 & {
          text-indent: 5.8em;
        }
        .w1600__ & {
          text-indent: 6.5em;
        }
      }
    }
    &__object {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  // work 간격
  .trademark {
    & + .footer-object-desc {
      margin-top: $em128;
    }
  }
